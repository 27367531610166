@keyframes rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

:root {
  --font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
  --font-headline-large: 600 32px / 40px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
  --font-headline-medium: 600 28px / 36px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
  --font-headline-small: 600 24px / 32px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
  --font-title-large: 600 20px / 28px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
  --font-title-medium: 600 16px / 24px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
  --font-title-small: 600 14px / 20px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
  --font-label-large: 500 14px / 20px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
  --font-label-medium: 500 12px / 16px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
  --font-label-small: 500 11px / 16px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
  --font-body-large: 400 16px / 24px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
  --font-body-medium: 400 14px / 20px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
  --font-body-small: 400 12px / 16px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
  --font-subhead-1: 500 16px / 24px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
  --font-subhead-2: 500 14px / 20px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
  --font-subhead-cap: 700 12px / 16px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
  --font-subhead-cap-small: 700 10px / 16px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
  --font-button: 500 14px / 20px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
}

body {
  background: var(--color-base);
  color: var(--color-text);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: auto;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

* {
  box-sizing: border-box;
}

input {
  border: none;
  outline: none;
}

table {
  border: 1px solid var(--color-divider);
  border-spacing: 0;
  width: 100%;
  table-layout: fixed;
}

table thead th {
  font: var(--font-subhead-2);
  color: var(--color-text);
  border-bottom: 1px solid var(--color-divider);
  text-align: left;
  padding: 12px;
}

table thead th:first-child, table thead th:last-child {
  padding: 12px 32px;
}

table tbody td {
  font: var(--font-body-medium);
  border-bottom: 1px solid var(--color-divider);
  padding: 12px 8px;
}

table tbody td:first-child, table tbody td:last-child {
  padding: 12px 32px;
}

::-webkit-scrollbar {
  width: 16px;
}

::-webkit-scrollbar:horizontal {
  height: 16px;
}

::-webkit-scrollbar-thumb {
  background: var(--color-neutral-variant-80);
  min-height: 40px;
  background-clip: content-box;
  border: 4px solid #0000;
  border-radius: 12px;
}

::-webkit-scrollbar-track {
  background: none;
}

@font-face {
  font-family: Roboto Mono;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-mono-cyrillic-ext-400-normal.dcb520ee.woff2") format("woff2"), url("roboto-mono-all-400-normal.364ec368.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Roboto Mono;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-mono-cyrillic-400-normal.638e826e.woff2") format("woff2"), url("roboto-mono-all-400-normal.364ec368.woff") format("woff");
  unicode-range: U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Roboto Mono;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-mono-greek-400-normal.dd843e41.woff2") format("woff2"), url("roboto-mono-all-400-normal.364ec368.woff") format("woff");
  unicode-range: U+370-3FF;
}

@font-face {
  font-family: Roboto Mono;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-mono-vietnamese-400-normal.306e7635.woff2") format("woff2"), url("roboto-mono-all-400-normal.364ec368.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Roboto Mono;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-mono-latin-ext-400-normal.d9409874.woff2") format("woff2"), url("roboto-mono-all-400-normal.364ec368.woff") format("woff");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Roboto Mono;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-mono-latin-400-normal.cf1eee5f.woff2") format("woff2"), url("roboto-mono-all-400-normal.364ec368.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.IbKR_q_light {
  --color-all-0: #000;
  --color-all-100: #fff;
  --color-primary-10: #190064;
  --color-primary-20: #2d009d;
  --color-primary-30: #4300da;
  --color-primary-40: #5d34f2;
  --color-primary-50: #7958ff;
  --color-primary-60: #947dff;
  --color-primary-70: #af9eff;
  --color-primary-80: #cabeff;
  --color-primary-90: #e6deff;
  --color-primary-95: #f5eeff;
  --color-primary-99: #fffbff;
  --color-secondary-10: #201c00;
  --color-secondary-20: #373100;
  --color-secondary-30: #4f4700;
  --color-secondary-40: #695f00;
  --color-secondary-50: #847900;
  --color-secondary-60: #9f930d;
  --color-secondary-70: #baad2d;
  --color-secondary-80: #d7c947;
  --color-secondary-90: #f4e560;
  --color-secondary-95: #fff480;
  --color-secondary-99: #fffbf7;
  --color-tertiary-10: #350041;
  --color-tertiary-20: #560068;
  --color-tertiary-30: #7b0093;
  --color-tertiary-40: #9927af;
  --color-tertiary-50: #b545ca;
  --color-tertiary-60: #d361e7;
  --color-tertiary-70: #f07eff;
  --color-tertiary-80: #faabff;
  --color-tertiary-90: #ffd5ff;
  --color-tertiary-95: #ffeafe;
  --color-tertiary-99: #fcfcfc;
  --color-error-10: #410001;
  --color-error-20: #680003;
  --color-error-30: #930006;
  --color-error-40: #ba1b1b;
  --color-error-50: #dd3730;
  --color-error-60: #ff5449;
  --color-error-70: #ff897a;
  --color-error-80: #ffb4a9;
  --color-error-90: #ffdad4;
  --color-error-95: #ffede9;
  --color-error-99: #fcfcfc;
  --color-neutral-10: #191c1d;
  --color-neutral-20: #2d3132;
  --color-neutral-30: #444748;
  --color-neutral-40: #5c5f60;
  --color-neutral-50: #747778;
  --color-neutral-60: #8e9192;
  --color-neutral-70: #a9acac;
  --color-neutral-80: #c4c7c7;
  --color-neutral-90: #e0e3e3;
  --color-neutral-95: #eff1f1;
  --color-neutral-99: #f7f8f8;
  --color-neutral-variant-10: #1b1b22;
  --color-neutral-variant-20: #302f38;
  --color-neutral-variant-30: #47464e;
  --color-neutral-variant-40: #5f5d67;
  --color-neutral-variant-50: #78767f;
  --color-neutral-variant-60: #928f9a;
  --color-neutral-variant-70: #adaab4;
  --color-neutral-variant-80: #c9c5d0;
  --color-neutral-variant-90: #e5e1ec;
  --color-neutral-variant-95: #f3effa;
  --color-neutral-variant-99: #fffbff;
  --color-success-10: #002106;
  --color-success-20: #00390b;
  --color-success-30: #005314;
  --color-success-40: #116d26;
  --color-success-50: #32873d;
  --color-success-60: #4ea254;
  --color-success-70: #68be6c;
  --color-success-80: #83da85;
  --color-success-90: #9fe79f;
  --color-success-95: #bdefbd;
  --color-success-99: #ebf9eb;
  --color-alert-10: #2b1700;
  --color-alert-20: #472a00;
  --color-alert-30: #663e00;
  --color-alert-40: #865300;
  --color-alert-50: #a76900;
  --color-alert-60: #ca8000;
  --color-alert-70: #eb9918;
  --color-alert-80: #ffb95a;
  --color-alert-90: #ffddb5;
  --color-alert-95: #ffeedc;
  --color-alert-99: #fff5eb;
  --color-primary: var(--color-primary-40);
  --color-on-primary: var(--color-all-100);
  --color-primary-container: var(--color-primary-90);
  --color-on-primary-container: var(--color-primary-10);
  --color-secondary: var(--color-secondary-40);
  --color-on-secondary: var(--color-all-100);
  --color-secondary-container: var(--color-secondary-30);
  --color-on-secondary-container: var(--color-secondary-10);
  --color-tertiary: var(--color-tertiary-40);
  --color-on-tertiary: var(--color-all-100);
  --color-tertiary-container: var(--color-tertiary-90);
  --color-on-tertiary-container: var(--color-tertiary-10);
  --color-error: var(--color-error-40);
  --color-on-error: var(--color-all-100);
  --color-error-container: var(--color-error-90);
  --color-on-error-container: var(--color-error-10);
  --color-background: var(--color-neutral-99);
  --color-on-background: var(--color-neutral-10);
  --color-surface: var(--color-neutral-99);
  --color-surface-1: #ecebf6;
  --color-surface-2: #e8e6f6;
  --color-surface-3: #e3e0f6;
  --color-surface-4: #dfdaf5;
  --color-surface-5: #dcd6f5;
  --color-on-surface: var(--color-neutral-10);
  --color-surface-variant: var(--color-neutral-variant-90);
  --color-on-surface-variant: var(--color-neutral-variant-30);
  --color-outline: var(--color-neutral-variant-50);
  --color-primary-hover: var(--color-primary-50);
  --color-primary-pressed: var(--color-primary-30);
  --color-danger-hover: var(--color-error-50);
  --color-danger-pressed: var(--color-error-30);
  --color-text: var(--color-neutral-10);
  --color-text-link: var(--color-primary-40);
  --color-text-secondary: var(--color-neutral-50);
  --color-placeholder: var(--color-neutral-70);
  --color-border: var(--color-neutral-80);
  --color-divider: var(--color-neutral-90);
  --color-disabled: var(--color-neutral-80);
  --color-base: var(--color-surface-1);
  --color-layer-1: var(--color-all-100);
  --color-layer-2: var(--color-neutral-95);
  --color-float: var(--color-all-100);
  --color-layer-light: var(--color-neutral-99);
  --color-inverse-surface: var(--color-neutral-20);
  --color-inverse-on-surface: var(--color-neutral-95);
  --color-inverse-primary: var(--color-primary-50);
  --color-shadow: var(--color-all-100);
  --color-white: #fff;
  --color-button-icon: var(--color-primary-80);
  --color-icon-background: var(--color-neutral-variant-95);
  --color-hover: #191c1d14;
  --color-pressed: #191c1d1f;
  --color-focused: #191c1d29;
  --color-hover-variant: #5d34f214;
  --color-pressed-variant: #5d34f21f;
  --color-focused-variant: #5d34f229;
  --shadow-1: 0 4px 8px #00000014;
  --shadow-2: 0 4px 12px #0000001f;
  --shadow-2-reversed: 0 -4px 12px #0000001f;
  --shadow-3: 0 4px 16px #0003;
  --color-danger-toast-background: var(--color-error-95);
  --color-danger-focused: #ba1b1b29;
  --color-tooltip-background: #34353f;
  --color-tooltip-text: var(--color-neutral-99);
  --color-overlay: #0000004d;
  --color-drawer-overlay: #0006;
  --color-guide-dropdown-background: var(--color-white);
  --color-guide-dropdown-border: var(--color-border);
  --color-skeleton-shimmer-rgb: 255, 255, 255;
}

.IbKR_q_dark {
  --color-all-0: #fff;
  --color-all-100: #000;
  --color-primary-10: #fffbff;
  --color-primary-20: #f5eeff;
  --color-primary-30: #e6deff;
  --color-primary-40: #cabeff;
  --color-primary-50: #af9eff;
  --color-primary-60: #947dff;
  --color-primary-70: #7958ff;
  --color-primary-80: #5d34f2;
  --color-primary-90: #4300da;
  --color-primary-95: #2d009d;
  --color-primary-99: #190064;
  --color-secondary-10: #fffbf7;
  --color-secondary-20: #fff480;
  --color-secondary-30: #f4e560;
  --color-secondary-40: #d7c947;
  --color-secondary-50: #baad2d;
  --color-secondary-60: #9f930d;
  --color-secondary-70: #847900;
  --color-secondary-80: #695f00;
  --color-secondary-90: #4f4700;
  --color-secondary-95: #373100;
  --color-secondary-99: #201c00;
  --color-tertiary-10: #fcfcfc;
  --color-tertiary-20: #ffeafe;
  --color-tertiary-30: #ffd5ff;
  --color-tertiary-40: #faabff;
  --color-tertiary-50: #f07eff;
  --color-tertiary-60: #d361e7;
  --color-tertiary-70: #b545ca;
  --color-tertiary-80: #9927af;
  --color-tertiary-90: #7b0093;
  --color-tertiary-95: #560068;
  --color-tertiary-99: #350041;
  --color-error-10: #fcfcfc;
  --color-error-20: #ffede9;
  --color-error-30: #ffdad4;
  --color-error-40: #ffb4a9;
  --color-error-50: #ff897a;
  --color-error-60: #ff5449;
  --color-error-70: #dd3730;
  --color-error-80: #ba1b1b;
  --color-error-90: #930006;
  --color-error-95: #680003;
  --color-error-99: #410001;
  --color-neutral-10: #f7f8f8;
  --color-neutral-20: #eff1f1;
  --color-neutral-30: #e0e3e3;
  --color-neutral-40: #c4c7c7;
  --color-neutral-50: #a9acac;
  --color-neutral-60: #8e9192;
  --color-neutral-70: #747778;
  --color-neutral-80: #5c5f60;
  --color-neutral-90: #444748;
  --color-neutral-95: #2d3132;
  --color-neutral-99: #191c1d;
  --color-neutral-variant-10: #fffbff;
  --color-neutral-variant-20: #f3effa;
  --color-neutral-variant-30: #e5e1ec;
  --color-neutral-variant-40: #c9c5d0;
  --color-neutral-variant-50: #adaab4;
  --color-neutral-variant-60: #928f9a;
  --color-neutral-variant-70: #78767f;
  --color-neutral-variant-80: #5f5d67;
  --color-neutral-variant-90: #47464e;
  --color-neutral-variant-95: #302f38;
  --color-neutral-variant-99: #1b1b22;
  --color-success-10: #ebf9eb;
  --color-success-20: #bdefbd;
  --color-success-30: #9fe79f;
  --color-success-40: #83da85;
  --color-success-50: #68be6c;
  --color-success-60: #4ea254;
  --color-success-70: #32873d;
  --color-success-80: #116d26;
  --color-success-90: #005314;
  --color-success-95: #00390b;
  --color-success-99: #002106;
  --color-alert-10: #fff5eb;
  --color-alert-20: #ffeedc;
  --color-alert-30: #ffddb5;
  --color-alert-40: #ffb95a;
  --color-alert-50: #eb9918;
  --color-alert-60: #ca8000;
  --color-alert-70: #a76900;
  --color-alert-80: #865300;
  --color-alert-90: #663e00;
  --color-alert-95: #472a00;
  --color-alert-99: #2b1700;
  --color-primary: var(--color-primary-70);
  --color-on-primary: var(--color-all-0);
  --color-primary-container: var(--color-primary-30);
  --color-on-primary-container: var(--color-primary-30);
  --color-secondary: var(--color-secondary-70);
  --color-on-secondary: var(--color-all-0);
  --color-secondary-container: var(--color-secondary-90);
  --color-on-secondary-container: var(--color-secondary-30);
  --color-tertiary: var(--color-tertiary-70);
  --color-on-tertiary: var(--color-all-0);
  --color-tertiary-container: var(--color-tertiary-90);
  --color-on-tertiary-container: var(--color-tertiary-30);
  --color-error: var(--color-error-70);
  --color-on-error: var(--color-all-0);
  --color-error-container: var(--color-error-90);
  --color-on-error-container: var(--color-error-30);
  --color-background: var(--color-neutral-99);
  --color-on-background: var(--color-neutral-10);
  --color-surface: var(--color-neutral-99);
  --color-surface-1: #25272b;
  --color-surface-2: #2a2c32;
  --color-surface-3: #2f3039;
  --color-surface-4: #34353f;
  --color-surface-5: #383844;
  --color-on-surface: var(--color-neutral-10);
  --color-surface-variant: var(--color-neutral-variant-90);
  --color-on-surface-variant: var(--color-neutral-variant-40);
  --color-outline: var(--color-neutral-variant-60);
  --color-primary-hover: var(--color-primary-60);
  --color-primary-pressed: var(--color-primary-80);
  --color-danger-hover: var(--color-error-60);
  --color-danger-pressed: var(--color-error-80);
  --color-text: var(--color-neutral-10);
  --color-text-link: var(--color-primary-40);
  --color-text-secondary: var(--color-neutral-50);
  --color-placeholder: var(--color-neutral-70);
  --color-border: var(--color-neutral-80);
  --color-divider: var(--color-neutral-90);
  --color-disabled: var(--color-neutral-80);
  --color-base: var(--color-surface);
  --color-layer-1: var(--color-surface-2);
  --color-layer-2: var(--color-surface-4);
  --color-float: var(--color-surface-4);
  --color-layer-light: var(--color-surface-4);
  --color-inverse-surface: var(--color-neutral-20);
  --color-inverse-on-surface: var(--color-neutral-95);
  --color-inverse-primary: var(--color-primary-80);
  --color-shadow: var(--color-all-100);
  --color-white: #fff;
  --color-button-icon: var(--color-primary-20);
  --color-icon-background: #3a3b59;
  --color-hover: #f7f8f814;
  --color-pressed: #f7f8f81f;
  --color-focused: #f7f8f829;
  --color-hover-variant: #cabeff14;
  --color-pressed-variant: #cabeff1f;
  --color-focused-variant: #cabeff29;
  --shadow-1: 0 4px 8px #00000014;
  --shadow-2: 0 4px 12px #0000001f;
  --shadow-2-reversed: 0 -4px 12px #0000001f;
  --shadow-3: 0 4px 16px #0003;
  --color-danger-toast-background: var(--color-error-99);
  --color-danger-focused: #ffb4a929;
  --color-tooltip-background: var(--color-surface-4);
  --color-tooltip-text: var(--color-neutral-10);
  --color-overlay: #0000004d;
  --color-drawer-overlay: #0009;
  --color-guide-dropdown-background: var(--color-neutral-variant-80);
  --color-guide-dropdown-border: var(--color-neutral-variant-70);
  --color-skeleton-shimmer-rgb: 42, 44, 50;
}

@media (prefers-color-scheme: light) {
  body {
    --color-all-0: #000;
    --color-all-100: #fff;
    --color-primary-10: #190064;
    --color-primary-20: #2d009d;
    --color-primary-30: #4300da;
    --color-primary-40: #5d34f2;
    --color-primary-50: #7958ff;
    --color-primary-60: #947dff;
    --color-primary-70: #af9eff;
    --color-primary-80: #cabeff;
    --color-primary-90: #e6deff;
    --color-primary-95: #f5eeff;
    --color-primary-99: #fffbff;
    --color-secondary-10: #201c00;
    --color-secondary-20: #373100;
    --color-secondary-30: #4f4700;
    --color-secondary-40: #695f00;
    --color-secondary-50: #847900;
    --color-secondary-60: #9f930d;
    --color-secondary-70: #baad2d;
    --color-secondary-80: #d7c947;
    --color-secondary-90: #f4e560;
    --color-secondary-95: #fff480;
    --color-secondary-99: #fffbf7;
    --color-tertiary-10: #350041;
    --color-tertiary-20: #560068;
    --color-tertiary-30: #7b0093;
    --color-tertiary-40: #9927af;
    --color-tertiary-50: #b545ca;
    --color-tertiary-60: #d361e7;
    --color-tertiary-70: #f07eff;
    --color-tertiary-80: #faabff;
    --color-tertiary-90: #ffd5ff;
    --color-tertiary-95: #ffeafe;
    --color-tertiary-99: #fcfcfc;
    --color-error-10: #410001;
    --color-error-20: #680003;
    --color-error-30: #930006;
    --color-error-40: #ba1b1b;
    --color-error-50: #dd3730;
    --color-error-60: #ff5449;
    --color-error-70: #ff897a;
    --color-error-80: #ffb4a9;
    --color-error-90: #ffdad4;
    --color-error-95: #ffede9;
    --color-error-99: #fcfcfc;
    --color-neutral-10: #191c1d;
    --color-neutral-20: #2d3132;
    --color-neutral-30: #444748;
    --color-neutral-40: #5c5f60;
    --color-neutral-50: #747778;
    --color-neutral-60: #8e9192;
    --color-neutral-70: #a9acac;
    --color-neutral-80: #c4c7c7;
    --color-neutral-90: #e0e3e3;
    --color-neutral-95: #eff1f1;
    --color-neutral-99: #f7f8f8;
    --color-neutral-variant-10: #1b1b22;
    --color-neutral-variant-20: #302f38;
    --color-neutral-variant-30: #47464e;
    --color-neutral-variant-40: #5f5d67;
    --color-neutral-variant-50: #78767f;
    --color-neutral-variant-60: #928f9a;
    --color-neutral-variant-70: #adaab4;
    --color-neutral-variant-80: #c9c5d0;
    --color-neutral-variant-90: #e5e1ec;
    --color-neutral-variant-95: #f3effa;
    --color-neutral-variant-99: #fffbff;
    --color-success-10: #002106;
    --color-success-20: #00390b;
    --color-success-30: #005314;
    --color-success-40: #116d26;
    --color-success-50: #32873d;
    --color-success-60: #4ea254;
    --color-success-70: #68be6c;
    --color-success-80: #83da85;
    --color-success-90: #9fe79f;
    --color-success-95: #bdefbd;
    --color-success-99: #ebf9eb;
    --color-alert-10: #2b1700;
    --color-alert-20: #472a00;
    --color-alert-30: #663e00;
    --color-alert-40: #865300;
    --color-alert-50: #a76900;
    --color-alert-60: #ca8000;
    --color-alert-70: #eb9918;
    --color-alert-80: #ffb95a;
    --color-alert-90: #ffddb5;
    --color-alert-95: #ffeedc;
    --color-alert-99: #fff5eb;
    --color-primary: var(--color-primary-40);
    --color-on-primary: var(--color-all-100);
    --color-primary-container: var(--color-primary-90);
    --color-on-primary-container: var(--color-primary-10);
    --color-secondary: var(--color-secondary-40);
    --color-on-secondary: var(--color-all-100);
    --color-secondary-container: var(--color-secondary-30);
    --color-on-secondary-container: var(--color-secondary-10);
    --color-tertiary: var(--color-tertiary-40);
    --color-on-tertiary: var(--color-all-100);
    --color-tertiary-container: var(--color-tertiary-90);
    --color-on-tertiary-container: var(--color-tertiary-10);
    --color-error: var(--color-error-40);
    --color-on-error: var(--color-all-100);
    --color-error-container: var(--color-error-90);
    --color-on-error-container: var(--color-error-10);
    --color-background: var(--color-neutral-99);
    --color-on-background: var(--color-neutral-10);
    --color-surface: var(--color-neutral-99);
    --color-surface-1: #ecebf6;
    --color-surface-2: #e8e6f6;
    --color-surface-3: #e3e0f6;
    --color-surface-4: #dfdaf5;
    --color-surface-5: #dcd6f5;
    --color-on-surface: var(--color-neutral-10);
    --color-surface-variant: var(--color-neutral-variant-90);
    --color-on-surface-variant: var(--color-neutral-variant-30);
    --color-outline: var(--color-neutral-variant-50);
    --color-primary-hover: var(--color-primary-50);
    --color-primary-pressed: var(--color-primary-30);
    --color-danger-hover: var(--color-error-50);
    --color-danger-pressed: var(--color-error-30);
    --color-text: var(--color-neutral-10);
    --color-text-link: var(--color-primary-40);
    --color-text-secondary: var(--color-neutral-50);
    --color-placeholder: var(--color-neutral-70);
    --color-border: var(--color-neutral-80);
    --color-divider: var(--color-neutral-90);
    --color-disabled: var(--color-neutral-80);
    --color-base: var(--color-surface-1);
    --color-layer-1: var(--color-all-100);
    --color-layer-2: var(--color-neutral-95);
    --color-float: var(--color-all-100);
    --color-layer-light: var(--color-neutral-99);
    --color-inverse-surface: var(--color-neutral-20);
    --color-inverse-on-surface: var(--color-neutral-95);
    --color-inverse-primary: var(--color-primary-50);
    --color-shadow: var(--color-all-100);
    --color-white: #fff;
    --color-button-icon: var(--color-primary-80);
    --color-icon-background: var(--color-neutral-variant-95);
    --color-hover: #191c1d14;
    --color-pressed: #191c1d1f;
    --color-focused: #191c1d29;
    --color-hover-variant: #5d34f214;
    --color-pressed-variant: #5d34f21f;
    --color-focused-variant: #5d34f229;
    --shadow-1: 0 4px 8px #00000014;
    --shadow-2: 0 4px 12px #0000001f;
    --shadow-2-reversed: 0 -4px 12px #0000001f;
    --shadow-3: 0 4px 16px #0003;
    --color-danger-toast-background: var(--color-error-95);
    --color-danger-focused: #ba1b1b29;
    --color-tooltip-background: #34353f;
    --color-tooltip-text: var(--color-neutral-99);
    --color-overlay: #0000004d;
    --color-drawer-overlay: #0006;
    --color-guide-dropdown-background: var(--color-white);
    --color-guide-dropdown-border: var(--color-border);
    --color-skeleton-shimmer-rgb: 255, 255, 255;
  }
}

@media (prefers-color-scheme: dark) {
  body {
    --color-all-0: #fff;
    --color-all-100: #000;
    --color-primary-10: #fffbff;
    --color-primary-20: #f5eeff;
    --color-primary-30: #e6deff;
    --color-primary-40: #cabeff;
    --color-primary-50: #af9eff;
    --color-primary-60: #947dff;
    --color-primary-70: #7958ff;
    --color-primary-80: #5d34f2;
    --color-primary-90: #4300da;
    --color-primary-95: #2d009d;
    --color-primary-99: #190064;
    --color-secondary-10: #fffbf7;
    --color-secondary-20: #fff480;
    --color-secondary-30: #f4e560;
    --color-secondary-40: #d7c947;
    --color-secondary-50: #baad2d;
    --color-secondary-60: #9f930d;
    --color-secondary-70: #847900;
    --color-secondary-80: #695f00;
    --color-secondary-90: #4f4700;
    --color-secondary-95: #373100;
    --color-secondary-99: #201c00;
    --color-tertiary-10: #fcfcfc;
    --color-tertiary-20: #ffeafe;
    --color-tertiary-30: #ffd5ff;
    --color-tertiary-40: #faabff;
    --color-tertiary-50: #f07eff;
    --color-tertiary-60: #d361e7;
    --color-tertiary-70: #b545ca;
    --color-tertiary-80: #9927af;
    --color-tertiary-90: #7b0093;
    --color-tertiary-95: #560068;
    --color-tertiary-99: #350041;
    --color-error-10: #fcfcfc;
    --color-error-20: #ffede9;
    --color-error-30: #ffdad4;
    --color-error-40: #ffb4a9;
    --color-error-50: #ff897a;
    --color-error-60: #ff5449;
    --color-error-70: #dd3730;
    --color-error-80: #ba1b1b;
    --color-error-90: #930006;
    --color-error-95: #680003;
    --color-error-99: #410001;
    --color-neutral-10: #f7f8f8;
    --color-neutral-20: #eff1f1;
    --color-neutral-30: #e0e3e3;
    --color-neutral-40: #c4c7c7;
    --color-neutral-50: #a9acac;
    --color-neutral-60: #8e9192;
    --color-neutral-70: #747778;
    --color-neutral-80: #5c5f60;
    --color-neutral-90: #444748;
    --color-neutral-95: #2d3132;
    --color-neutral-99: #191c1d;
    --color-neutral-variant-10: #fffbff;
    --color-neutral-variant-20: #f3effa;
    --color-neutral-variant-30: #e5e1ec;
    --color-neutral-variant-40: #c9c5d0;
    --color-neutral-variant-50: #adaab4;
    --color-neutral-variant-60: #928f9a;
    --color-neutral-variant-70: #78767f;
    --color-neutral-variant-80: #5f5d67;
    --color-neutral-variant-90: #47464e;
    --color-neutral-variant-95: #302f38;
    --color-neutral-variant-99: #1b1b22;
    --color-success-10: #ebf9eb;
    --color-success-20: #bdefbd;
    --color-success-30: #9fe79f;
    --color-success-40: #83da85;
    --color-success-50: #68be6c;
    --color-success-60: #4ea254;
    --color-success-70: #32873d;
    --color-success-80: #116d26;
    --color-success-90: #005314;
    --color-success-95: #00390b;
    --color-success-99: #002106;
    --color-alert-10: #fff5eb;
    --color-alert-20: #ffeedc;
    --color-alert-30: #ffddb5;
    --color-alert-40: #ffb95a;
    --color-alert-50: #eb9918;
    --color-alert-60: #ca8000;
    --color-alert-70: #a76900;
    --color-alert-80: #865300;
    --color-alert-90: #663e00;
    --color-alert-95: #472a00;
    --color-alert-99: #2b1700;
    --color-primary: var(--color-primary-70);
    --color-on-primary: var(--color-all-0);
    --color-primary-container: var(--color-primary-30);
    --color-on-primary-container: var(--color-primary-30);
    --color-secondary: var(--color-secondary-70);
    --color-on-secondary: var(--color-all-0);
    --color-secondary-container: var(--color-secondary-90);
    --color-on-secondary-container: var(--color-secondary-30);
    --color-tertiary: var(--color-tertiary-70);
    --color-on-tertiary: var(--color-all-0);
    --color-tertiary-container: var(--color-tertiary-90);
    --color-on-tertiary-container: var(--color-tertiary-30);
    --color-error: var(--color-error-70);
    --color-on-error: var(--color-all-0);
    --color-error-container: var(--color-error-90);
    --color-on-error-container: var(--color-error-30);
    --color-background: var(--color-neutral-99);
    --color-on-background: var(--color-neutral-10);
    --color-surface: var(--color-neutral-99);
    --color-surface-1: #25272b;
    --color-surface-2: #2a2c32;
    --color-surface-3: #2f3039;
    --color-surface-4: #34353f;
    --color-surface-5: #383844;
    --color-on-surface: var(--color-neutral-10);
    --color-surface-variant: var(--color-neutral-variant-90);
    --color-on-surface-variant: var(--color-neutral-variant-40);
    --color-outline: var(--color-neutral-variant-60);
    --color-primary-hover: var(--color-primary-60);
    --color-primary-pressed: var(--color-primary-80);
    --color-danger-hover: var(--color-error-60);
    --color-danger-pressed: var(--color-error-80);
    --color-text: var(--color-neutral-10);
    --color-text-link: var(--color-primary-40);
    --color-text-secondary: var(--color-neutral-50);
    --color-placeholder: var(--color-neutral-70);
    --color-border: var(--color-neutral-80);
    --color-divider: var(--color-neutral-90);
    --color-disabled: var(--color-neutral-80);
    --color-base: var(--color-surface);
    --color-layer-1: var(--color-surface-2);
    --color-layer-2: var(--color-surface-4);
    --color-float: var(--color-surface-4);
    --color-layer-light: var(--color-surface-4);
    --color-inverse-surface: var(--color-neutral-20);
    --color-inverse-on-surface: var(--color-neutral-95);
    --color-inverse-primary: var(--color-primary-80);
    --color-shadow: var(--color-all-100);
    --color-white: #fff;
    --color-button-icon: var(--color-primary-20);
    --color-icon-background: #3a3b59;
    --color-hover: #f7f8f814;
    --color-pressed: #f7f8f81f;
    --color-focused: #f7f8f829;
    --color-hover-variant: #cabeff14;
    --color-pressed-variant: #cabeff1f;
    --color-focused-variant: #cabeff29;
    --shadow-1: 0 4px 8px #00000014;
    --shadow-2: 0 4px 12px #0000001f;
    --shadow-2-reversed: 0 -4px 12px #0000001f;
    --shadow-3: 0 4px 16px #0003;
    --color-danger-toast-background: var(--color-error-99);
    --color-danger-focused: #ffb4a929;
    --color-tooltip-background: var(--color-surface-4);
    --color-tooltip-text: var(--color-neutral-10);
    --color-overlay: #0000004d;
    --color-drawer-overlay: #0009;
    --color-guide-dropdown-background: var(--color-neutral-variant-80);
    --color-guide-dropdown-border: var(--color-neutral-variant-70);
    --color-skeleton-shimmer-rgb: 42, 44, 50;
  }
}

@keyframes Mn-v3W_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes Mn-v3W_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.Mn-v3W_container {
  background-color: var(--color-surface-1);
  color: var(--color-text);
  height: 100vh;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  display: flex;
  overflow-y: auto;
}

.Mn-v3W_container > * {
  margin-top: 24px;
}

.Mn-v3W_container > svg {
  height: 256px;
  width: 256px;
  flex-shrink: 0;
  margin-top: 120px;
}

.Mn-v3W_container label {
  font: var(--font-title-large);
}

.Mn-v3W_container .Mn-v3W_summary {
  max-width: 470px;
  font: var(--font-body-medium);
  text-align: center;
  align-items: center;
  margin-top: 16px;
  display: flex;
}

.Mn-v3W_container .Mn-v3W_summary .Mn-v3W_expander {
  color: var(--color-primary);
  cursor: pointer;
  align-items: center;
  margin-left: 8px;
  display: inline-flex;
}

.Mn-v3W_container .Mn-v3W_summary svg {
  width: 20px;
  height: 20px;
  color: var(--color-primary);
}

.Mn-v3W_container .Mn-v3W_details {
  width: 470px;
  background-color: var(--color-layer-1);
  font: var(--font-body-medium);
  white-space: pre-wrap;
  word-break: break-all;
  border-radius: 16px;
  padding: 24px;
}

@keyframes f917Mq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes f917Mq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.f917Mq_ring {
  animation: 1s ease-in-out infinite f917Mq_rotating;
}

.f917Mq_daisy {
  animation: 1s steps(12, end) infinite f917Mq_rotating;
}

@keyframes FA5ZUG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes FA5ZUG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.FA5ZUG_container {
  height: 100vh;
  color: var(--color-text);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.FA5ZUG_container svg {
  margin-bottom: 16px;
}

@keyframes SfX6tW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes SfX6tW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.SfX6tW_button {
  font: var(--font-button);
  white-space: nowrap;
  -webkit-user-select: none;
  user-select: none;
  border: none;
  border-radius: 8px;
  outline: none;
  justify-content: center;
  align-items: center;
  transition: background-color .2s ease-in-out;
  display: flex;
  position: relative;
}

.SfX6tW_button.SfX6tW_withIcon {
  display: inline-flex;
}

.SfX6tW_button.SfX6tW_loading {
  pointer-events: none;
  opacity: .6;
}

.SfX6tW_button.SfX6tW_loading .SfX6tW_spinner {
  position: absolute;
}

.SfX6tW_button.SfX6tW_loading .SfX6tW_spinner ~ span {
  visibility: hidden;
}

.SfX6tW_button:not(:disabled) {
  cursor: pointer;
}

.SfX6tW_button:disabled {
  cursor: not-allowed;
  pointer-events: none;
}

.SfX6tW_button .SfX6tW_icon {
  width: 20px;
  height: 20px;
  display: block;
}

.SfX6tW_button .SfX6tW_icon:not(:last-child) {
  margin-right: 8px;
}

.SfX6tW_button.SfX6tW_small {
  height: 30px;
  padding: 0 12px;
}

.SfX6tW_button.SfX6tW_small.SfX6tW_text {
  height: 24px;
}

.SfX6tW_button.SfX6tW_medium {
  height: 36px;
  padding: 0 16px;
}

.SfX6tW_button.SfX6tW_medium.SfX6tW_text {
  font: var(--font-subhead-1);
  height: 28px;
}

.SfX6tW_button.SfX6tW_large {
  height: 44px;
  padding: 0 24px;
}

.SfX6tW_button.SfX6tW_large.SfX6tW_text {
  font: var(--font-subhead-1);
  height: 28px;
}

.SfX6tW_button.SfX6tW_default {
  background: var(--color-layer-1);
  color: var(--color-text);
  border-color: var(--color-border);
  border-style: solid;
  border-width: 1px;
}

.SfX6tW_button.SfX6tW_default:disabled {
  background: var(--color-neutral-70);
  border-color: var(--color-neutral-70);
}

.SfX6tW_button.SfX6tW_default:focus-visible {
  outline: 3px solid var(--color-focused);
}

.SfX6tW_button.SfX6tW_default:active {
  background: var(--color-pressed);
}

.SfX6tW_button.SfX6tW_default:not(:disabled):not(:active):hover {
  background: var(--color-hover);
}

.SfX6tW_button.SfX6tW_branding {
  color: var(--color-on-primary);
  background: linear-gradient(90deg, #5d34f2 32%, #bd31ff 102%);
}

.SfX6tW_button.SfX6tW_branding:focus-visible {
  background: linear-gradient(0deg, #0003, #0003), linear-gradient(90deg, #5d34f2 32%, #bd31ff 102%);
}

.SfX6tW_button.SfX6tW_branding:active {
  background: linear-gradient(0deg, #0003, #0003), linear-gradient(90deg, #5d34f2 32%, #bd31ff 102%);
}

.SfX6tW_button.SfX6tW_branding:not(:disabled):not(:active):hover {
  background: linear-gradient(0deg, #fff3, #fff3), linear-gradient(90deg, #5d34f2 32%, #bd31ff 102%);
}

.SfX6tW_button.SfX6tW_primary {
  background: var(--color-primary);
  color: var(--color-on-primary);
}

.SfX6tW_button.SfX6tW_primary:disabled {
  background: var(--color-inverse-on-surface);
  color: var(--color-neutral-70);
}

.SfX6tW_button.SfX6tW_primary:focus-visible {
  outline: 3px solid var(--color-focused-variant);
}

.SfX6tW_button.SfX6tW_primary:active {
  background: var(--color-primary-pressed);
}

.SfX6tW_button.SfX6tW_primary:not(:disabled):not(:active):hover {
  background: var(--color-primary-hover);
}

.SfX6tW_button.SfX6tW_danger {
  background: var(--color-error);
  color: var(--color-on-primary);
}

.SfX6tW_button.SfX6tW_danger:disabled {
  background: var(--color-inverse-on-surface);
  color: var(--color-neutral-70);
}

.SfX6tW_button.SfX6tW_danger:focus-visible {
  outline: 3px solid var(--color-danger-focused);
}

.SfX6tW_button.SfX6tW_danger:active {
  background: var(--color-danger-pressed);
}

.SfX6tW_button.SfX6tW_danger:not(:disabled):not(:active):hover {
  background: var(--color-danger-hover);
}

.SfX6tW_button.SfX6tW_outline {
  background: var(--color-layer-1);
  border-color: var(--color-text-link);
  color: var(--color-text-link);
  border-style: solid;
  border-width: 1px;
}

.SfX6tW_button.SfX6tW_outline:disabled {
  border-color: var(--color-neutral-70);
  color: var(--color-neutral-70);
}

.SfX6tW_button.SfX6tW_outline:focus-visible {
  outline: 3px solid var(--color-focused-variant);
}

.SfX6tW_button.SfX6tW_outline:active {
  background: var(--color-pressed-variant);
}

.SfX6tW_button.SfX6tW_outline:not(:disabled):not(:active):hover {
  background: var(--color-hover-variant);
}

.SfX6tW_button.SfX6tW_text {
  border-color: none;
  font: var(--font-label-large);
  color: var(--color-text-link);
  background: none;
  border-radius: 4px;
  padding: 2px 4px;
}

.SfX6tW_button.SfX6tW_text:disabled {
  color: var(--color-disabled);
}

.SfX6tW_button.SfX6tW_text:focus-visible {
  outline: 2px solid var(--color-focused-variant);
}

.SfX6tW_button.SfX6tW_text:not(:disabled):hover {
  background-color: var(--color-hover-variant);
}

@keyframes S7dkFG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes S7dkFG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.S7dkFG_retryButton {
  flex-shrink: 0;
  margin-top: 24px;
}

@keyframes ruyPuG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes ruyPuG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.ruyPuG_row {
  color: var(--color-neutral-variant-30);
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  width: calc(100% - 40px);
  background: none;
  border: none;
  border-radius: 8px;
  align-items: center;
  margin: 4px 24px 4px 16px;
  padding: 8px;
  text-decoration: none;
  display: flex;
}

.ruyPuG_row .ruyPuG_icon {
  height: 20px;
  flex: 0 0 20px;
}

.ruyPuG_row .ruyPuG_icon svg {
  color: var(--color-neutral-variant-50);
  width: 20px;
  height: 20px;
}

.ruyPuG_row:not(.ruyPuG_active):hover {
  background: var(--color-hover-variant);
}

.ruyPuG_row.ruyPuG_active {
  background: var(--color-surface-5);
  color: var(--color-text-link);
}

.ruyPuG_row.ruyPuG_active .ruyPuG_icon svg {
  color: var(--color-primary-50);
}

.ruyPuG_row > div + div {
  margin-left: 16px;
}

.ruyPuG_row .ruyPuG_title {
  font: var(--font-subhead-2);
}

@keyframes _EhJ-q_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _EhJ-q_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._EhJ-q_title {
  color: var(--color-text-secondary);
  font: var(--font-subhead-cap);
  letter-spacing: .1em;
  text-transform: uppercase;
  font: var(--font-subhead-cap-small);
  color: var(--color-neutral-variant-70);
  padding: 8px 24px;
}

@keyframes jPkTZa_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes jPkTZa_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.jPkTZa_card {
  background: var(--color-layer-1);
  border-radius: 16px;
  padding: 24px;
}

@keyframes GjtMga_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes GjtMga_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.GjtMga_container {
  overflow: hidden;
}

.GjtMga_container .GjtMga_title {
  color: var(--color-text);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.GjtMga_container .GjtMga_subtitle {
  color: var(--color-text-secondary);
  margin-top: 4px;
}

.GjtMga_container.GjtMga_large .GjtMga_title {
  font: var(--font-title-large);
}

.GjtMga_container.GjtMga_large .GjtMga_subtitle {
  font: var(--font-body-medium);
}

.GjtMga_container.GjtMga_medium .GjtMga_title {
  font: var(--font-title-medium);
}

.GjtMga_container.GjtMga_medium .GjtMga_subtitle {
  font: var(--font-body-medium);
}

.GjtMga_container.GjtMga_small .GjtMga_title {
  font: var(--font-title-medium);
}

.GjtMga_container.GjtMga_small .GjtMga_subtitle {
  font: var(--font-body-small);
  margin-top: 0;
}

@keyframes JY43xG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes JY43xG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.JY43xG_tipBubble {
  background: var(--color-tooltip-background);
  color: var(--color-tooltip-text);
  box-shadow: var(--shadow-1);
  font: var(--font-body-medium);
  max-width: 300px;
  border-radius: 8px;
  padding: 8px 12px;
  position: relative;
}

.JY43xG_tipBubble:after {
  content: "";
  width: 10px;
  height: 10px;
  background-color: inherit;
  border-radius: 2px 0;
  display: block;
  position: absolute;
  transform: translate(-50%, -50%)rotate(45deg);
}

.JY43xG_tipBubble.JY43xG_top:after {
  top: 100%;
}

.JY43xG_tipBubble.JY43xG_right:after {
  top: 50%;
  left: 0%;
}

.JY43xG_tipBubble.JY43xG_bottom:after {
  top: 0%;
}

.JY43xG_tipBubble.JY43xG_left:after {
  top: 50%;
  left: 100%;
}

.JY43xG_tipBubble.JY43xG_start:after {
  left: 40px;
}

.JY43xG_tipBubble.JY43xG_center:after {
  left: 50%;
}

.JY43xG_tipBubble.JY43xG_end:after {
  right: 30px;
}

@keyframes GekOPq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes GekOPq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.GekOPq_tooltip {
  position: absolute;
}

.GekOPq_tooltip .GekOPq_content {
  -webkit-line-clamp: 6;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

@keyframes _0JmSgq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _0JmSgq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._0JmSgq_button {
  border: none;
  border-color: none;
  color: var(--color-primary);
  font: var(--font-button);
  background: none;
  border-radius: 6px;
  outline: none;
  justify-content: center;
  align-items: center;
  padding: 4px;
  transition: background .2s ease-in-out;
  display: flex;
}

._0JmSgq_button ._0JmSgq_icon > svg {
  color: var(--color-text-secondary);
  display: block;
}

._0JmSgq_button:disabled ._0JmSgq_icon > svg {
  color: var(--color-neutral-80);
}

._0JmSgq_button:active {
  background: var(--color-pressed);
}

._0JmSgq_button:not(:disabled) {
  cursor: pointer;
}

._0JmSgq_button:not(:disabled):hover {
  background: var(--color-hover);
}

._0JmSgq_button._0JmSgq_small {
  height: 24px;
  width: 24px;
}

._0JmSgq_button._0JmSgq_small ._0JmSgq_icon > svg {
  height: 16px;
  width: 16px;
}

._0JmSgq_button._0JmSgq_medium {
  height: 28px;
  width: 28px;
}

._0JmSgq_button._0JmSgq_medium ._0JmSgq_icon > svg {
  height: 20px;
  width: 20px;
}

._0JmSgq_button._0JmSgq_large {
  height: 32px;
  width: 32px;
}

._0JmSgq_button._0JmSgq_large ._0JmSgq_icon > svg {
  height: 24px;
  width: 24px;
}

@keyframes XBPPva_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes XBPPva_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.XBPPva_container {
  width: calc(100% - 48px);
  min-width: 352px;
  box-shadow: var(--shadow-3);
  flex-direction: column;
  margin: 0 24px;
  padding: 24px;
  display: flex;
}

.XBPPva_container .XBPPva_header {
  flex-shrink: 0;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 24px;
  display: flex;
}

.XBPPva_container .XBPPva_header .XBPPva_closeIcon {
  color: var(--color-text-secondary);
}

.XBPPva_container .XBPPva_footer {
  flex-shrink: 0;
  justify-content: flex-end;
  padding-top: 24px;
  display: flex;
}

.XBPPva_container .XBPPva_footer > :not(:first-child) {
  margin-left: 16px;
}

.XBPPva_container.XBPPva_medium {
  max-width: 600px;
}

.XBPPva_container.XBPPva_large {
  max-width: 784px;
}

.XBPPva_container.XBPPva_xlarge {
  max-width: 1224px;
}

@keyframes uLSymG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes uLSymG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.uLSymG_overlay {
  background: var(--color-overlay);
  padding: 48px 0;
  position: fixed;
  inset: 0;
  overflow-y: auto;
}

.uLSymG_content {
  min-height: 100%;
  flex-direction: column;
  flex: 0 0;
  justify-content: center;
  align-items: center;
  display: flex;
}

.uLSymG_content:focus-visible {
  outline: none;
}

.uLSymG_fullScreen {
  z-index: 100;
  position: fixed;
  inset: 0;
}

@keyframes om9xOq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes om9xOq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.om9xOq_main .om9xOq_row {
  border: 1px solid var(--color-divider);
  border-radius: 8px;
  align-items: center;
  padding: 12px 16px;
  display: flex;
}

.om9xOq_main .om9xOq_row .om9xOq_icon {
  margin-right: 24px;
}

.om9xOq_main .om9xOq_row:not(:last-child) {
  margin-bottom: 24px;
}

.om9xOq_main .om9xOq_row .om9xOq_text {
  flex: 1;
}

.om9xOq_main .om9xOq_row .om9xOq_text .om9xOq_title {
  font: var(--font-title-small);
  color: var(--color-text);
}

.om9xOq_main .om9xOq_row .om9xOq_text .om9xOq_description {
  font: var(--font-body-medium);
  color: var(--color-text-secondary);
}

.om9xOq_main .om9xOq_row .om9xOq_button {
  width: 90px;
}

@keyframes M3bfLW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes M3bfLW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.M3bfLW_sidebar {
  width: 248px;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  margin-bottom: 24px;
  display: flex;
  overflow-y: auto;
}

.M3bfLW_sidebar > div + div {
  margin-top: 24px;
}

.M3bfLW_sidebar .M3bfLW_spacer {
  flex: 1 1 0;
  margin: 0;
}

.oYXfRW_spacer {
  flex: 1;
}

@keyframes _90kR-a_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _90kR-a_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._90kR-a_content {
  box-shadow: var(--shadow-2);
  border-radius: 8px;
  position: absolute;
}

._90kR-a_content._90kR-a_onTop {
  box-shadow: var(--shadow-2-reversed);
}

._90kR-a_content:focus {
  outline: none;
}

._90kR-a_dropdownContainer {
  background: var(--color-float);
  border: 1px solid var(--color-divider);
  border-radius: 8px;
}

._90kR-a_title {
  color: var(--color-text-secondary);
  font: var(--font-subhead-cap);
  letter-spacing: .1em;
  text-transform: uppercase;
  padding: 12px;
}

._90kR-a_overlay {
  background: none;
  position: fixed;
  inset: 0;
}

._90kR-a_list {
  max-height: 288px;
  margin: 0;
  padding: 4px;
  overflow-y: auto;
}

@keyframes YIacqW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes YIacqW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.YIacqW_item {
  font: var(--font-body-medium);
  cursor: pointer;
  border-radius: 8px;
  align-items: center;
  padding: 8px;
  list-style: none;
  display: flex;
  overflow: hidden;
}

.YIacqW_item:hover {
  background: var(--color-hover);
}

.YIacqW_item.YIacqW_danger {
  color: var(--color-error);
}

.YIacqW_item .YIacqW_icon {
  align-items: center;
  margin-right: 16px;
  display: flex;
}

@keyframes wjiRLq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes wjiRLq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.wjiRLq_container {
  width: 28px;
  height: 28px;
  color: var(--color-text-link);
  background: var(--color-focused-variant);
  font: var(--font-title-medium);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.wjiRLq_container.wjiRLq_active {
  color: var(--color-on-primary);
  background: var(--color-primary);
}

.wjiRLq_container.wjiRLq_completed {
  background: var(--color-primary);
}

.wjiRLq_container.wjiRLq_completed > svg {
  color: var(--color-on-primary);
}

@keyframes _0i2oZG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _0i2oZG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._0i2oZG_container {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  border-radius: 8px;
  align-items: center;
  padding: 8px;
  transition: background-color .2s ease-in-out;
  display: flex;
}

._0i2oZG_container:hover {
  background-color: var(--color-hover-variant);
}

._0i2oZG_container._0i2oZG_active {
  background-color: var(--color-focused-variant);
}

._0i2oZG_container ._0i2oZG_icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

._0i2oZG_container span {
  font: var(--font-subhead-2);
  color: var(--color-text);
}

._0i2oZG_dropdownTitle {
  color: var(--color-text-secondary);
  font: var(--font-subhead-cap);
  letter-spacing: .1em;
  text-transform: uppercase;
  padding: 12px 20px 12px 16px;
}

._0i2oZG_dropdown {
  padding: 0;
}

._0i2oZG_index {
  width: 20px;
  height: 20px;
  font: var(--font-subhead-cap);
}

._0i2oZG_index > svg {
  width: 16px;
  height: 16px;
}

._0i2oZG_dropdownItem {
  height: 42px;
  border-top: 1px solid var(--color-hover);
  border-radius: unset;
  border-bottom: 1px solid #0000;
  padding: 0 20px 0 16px;
}

._0i2oZG_dropdownItem:last-child {
  border-bottom-right-radius: 7px;
  border-bottom-left-radius: 7px;
}

._0i2oZG_dropdownItem:hover:not(:last-child) {
  border-bottom-color: var(--color-hover);
}

._0i2oZG_dropdownItem:hover:not(:last-child) + ._0i2oZG_dropdownItem {
  border-top-color: #0000;
}

@keyframes shcCRa_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes shcCRa_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.shcCRa_container {
  border-radius: 8px;
  align-items: center;
  margin-left: 16px;
  padding: 8px;
  display: flex;
}

.shcCRa_container .shcCRa_image {
  background-color: var(--color-layer-2);
  width: 36px;
  height: 36px;
  border-radius: 6px;
  margin-right: 8px;
  position: relative;
  overflow: hidden;
}

.shcCRa_container .shcCRa_image:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite shcCRa_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.shcCRa_container .shcCRa_name {
  background-color: var(--color-layer-2);
  width: 85px;
  height: 20px;
  color: var(--color-text);
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.shcCRa_container .shcCRa_name:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite shcCRa_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

@keyframes hpWBIW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes hpWBIW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.hpWBIW_container {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  border-radius: 8px;
  align-items: center;
  margin-left: 16px;
  padding: 8px;
  transition: background-color .2s ease-in-out;
  display: flex;
}

.hpWBIW_container:hover {
  background-color: var(--color-hover-variant);
}

.hpWBIW_container.hpWBIW_active {
  background-color: var(--color-focused-variant);
}

.hpWBIW_container img {
  width: 36px;
  height: 36px;
  border-radius: 6px;
  margin-right: 8px;
}

.hpWBIW_container .hpWBIW_wrapper {
  flex-direction: column;
  display: flex;
}

.hpWBIW_container .hpWBIW_wrapper .hpWBIW_name {
  font: var(--font-subhead-2);
  color: var(--color-text);
}

.hpWBIW_container .hpWBIW_wrapper .hpWBIW_role {
  font: var(--font-body-small);
  color: var(--color-text-secondary);
}

.hpWBIW_dropdownItem {
  min-width: 170px;
  padding: 10px 8px;
}

.hpWBIW_dropdownItem.hpWBIW_loading {
  opacity: .6;
  cursor: default;
}

.hpWBIW_dropdownItem.hpWBIW_loading:hover {
  background-color: unset;
}

.hpWBIW_dropdownItem .hpWBIW_signOutIcon {
  color: var(--color-text-secondary);
}

.hpWBIW_dropdownItem .hpWBIW_spinner {
  margin-left: 24px;
}

@keyframes igbh3G_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes igbh3G_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.igbh3G_topbar {
  width: 100%;
  flex: 0 0 64px;
  align-items: center;
  padding: 0 24px;
  display: flex;
}

.igbh3G_topbar .igbh3G_logo {
  width: 94px;
  height: 30px;
  color: var(--color-text);
}

.igbh3G_topbar .igbh3G_line {
  border-left: 1px solid var(--color-border);
  width: 0;
  height: 20px;
  margin: 0 20px;
}

.igbh3G_topbar .igbh3G_text {
  font: var(--font-title-medium);
  color: var(--color-text);
}

@keyframes Z4IIka_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes Z4IIka_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.Z4IIka_app {
  flex-direction: column;
  display: flex;
  position: absolute;
  inset: 0;
}

.Z4IIka_topbarShadow {
  box-shadow: var(--shadow-2);
}

.Z4IIka_content {
  flex-grow: 1;
  display: flex;
  overflow: hidden;
}

.Z4IIka_main {
  flex-grow: 1;
  padding: 0 8px;
  overflow-y: scroll;
}

.Z4IIka_main > * {
  max-width: 1168px;
  min-width: 604px;
  margin: 0 auto;
}

@keyframes nplLDa_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes nplLDa_shimmer {
  100% {
    transform: translateX(100%);
  }
}

div.nplLDa_toast {
  font: var(--font-body-medium);
  color: var(--color-text);
  box-shadow: var(--shadow-1);
  max-width: 412px;
  border-radius: 8px;
  align-items: center;
  padding: 12px 16px;
  display: flex;
}

div.nplLDa_toast .nplLDa_image {
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
}

div.nplLDa_toast .nplLDa_message {
  flex: 1;
  margin: 0 12px;
}

div.nplLDa_toast.nplLDa_success {
  border: 1px solid var(--color-success-70);
  background-color: var(--color-success-99);
}

div.nplLDa_toast.nplLDa_error {
  border: 1px solid var(--color-error);
  background-color: var(--color-danger-toast-background);
  white-space: pre-line;
}

.nASDTG_content {
  min-width: 200px;
}

@keyframes pDxB8q_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes pDxB8q_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.pDxB8q_container {
  color: var(--color-text);
  font: var(--font-body-medium);
  cursor: default;
  border-radius: 6px;
  font-family: Roboto Mono, monospace;
  display: inline-block;
}

.pDxB8q_container.pDxB8q_contained {
  background: var(--color-layer-2);
  padding: 4px 4px 4px 12px;
}

.pDxB8q_container.pDxB8q_border {
  background: var(--color-layer-2);
  border: 1px solid var(--color-border);
  padding: 4px 12px;
}

.pDxB8q_container .pDxB8q_row {
  cursor: text;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.pDxB8q_container .pDxB8q_row .pDxB8q_content {
  text-overflow: ellipsis;
  flex: 1;
  overflow: hidden;
}

.pDxB8q_container .pDxB8q_row .pDxB8q_copyIcon {
  margin-left: 12px;
}

.pDxB8q_container .pDxB8q_row .pDxB8q_copyIcon svg {
  width: 16px;
  height: 16px;
}

.pDxB8q_successfulTooltip {
  background-color: var(--color-success-60);
}

@keyframes gKmepq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes gKmepq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.gKmepq_content {
  font: var(--font-body-medium);
}

.gKmepq_content > :not(:first-child) {
  margin: 24px 0 0;
}

@keyframes clFIpG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes clFIpG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.clFIpG_container {
  border: 1px solid var(--color-border);
  background: var(--color-layer-1);
  font: var(--font-body-medium);
  border-radius: 6px;
  outline: 3px solid #0000;
  padding: 8px 12px;
  transition-property: outline, border;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
}

.clFIpG_container.clFIpG_withIcon {
  align-items: center;
  display: flex;
}

.clFIpG_container.clFIpG_withIcon .clFIpG_icon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.clFIpG_container:focus-within {
  border-color: var(--color-primary);
  outline-color: var(--color-focused-variant);
}

.clFIpG_container input {
  width: 100%;
  -webkit-appearance: none;
  appearance: none;
  color: var(--color-text);
  font: var(--font-body-medium);
  background: none;
  padding: 0;
}

.clFIpG_container input::placeholder {
  color: var(--color-placeholder);
}

.clFIpG_container input:-webkit-autofill {
  box-shadow: 0 0 0 30px var(--color-layer-1) inset;
  -webkit-text-fill-color: var(--color-text);
  caret-color: var(--color-text);
}

.clFIpG_container input[type="date"] {
  height: 20px;
}

.clFIpG_container input[type="date"]::-webkit-calendar-picker-indicator {
  background-image: none;
  background-color: var(--color-text-secondary);
  width: 16px;
  height: 18px;
  -webkit-mask-image: url("calendar.5bc8703a.png");
  mask-image: url("calendar.5bc8703a.png");
  -webkit-mask-size: 20px 20px;
  mask-size: 20px 20px;
}

.clFIpG_container.clFIpG_disabled {
  background: var(--color-inverse-on-surface);
  color: var(--color-text-secondary);
  border-color: var(--color-border);
}

.clFIpG_container.clFIpG_readOnly {
  background: var(--color-layer-2);
  color: var(--color-text);
  border-color: var(--color-border);
}

.clFIpG_container.clFIpG_readOnly:focus-within {
  border-color: var(--color-border);
  outline-color: #0000;
}

.clFIpG_container.clFIpG_error {
  border-color: var(--color-error);
}

.clFIpG_container.clFIpG_error:focus-within {
  outline-color: var(--color-danger-focused);
}

.clFIpG_errorMessage {
  font: var(--font-body-medium);
  color: var(--color-error);
  margin-top: 4px;
}

@keyframes _7BqVIW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _7BqVIW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._7BqVIW_container {
  width: 100%;
  height: 60px;
  border: 1px solid var(--color-line-divider);
  background-color: var(--color-float);
  box-shadow: var(--shadow-3);
  border-radius: 12px 12px 0 0;
  justify-content: flex-end;
  padding: 12px 32px;
  display: flex;
  position: sticky;
  bottom: 0;
}

._7BqVIW_container > :not(:first-child) {
  margin-left: 12px;
}

@keyframes l9fJqW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes l9fJqW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.l9fJqW_container {
  flex-direction: column;
  flex-grow: 1;
  display: flex;
}

.l9fJqW_container > :not(:first-child) {
  margin-top: 16px;
}

.l9fJqW_container .l9fJqW_fields {
  flex-grow: 1;
}

.l9fJqW_container .l9fJqW_fields > :not(:first-child) {
  margin-top: 16px;
}

.l9fJqW_container .l9fJqW_fields:last-child {
  margin-bottom: 24px;
}

@keyframes ohuMpq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes ohuMpq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.ohuMpq_container {
  height: 100%;
  flex-direction: column;
  display: flex;
  overflow-y: auto;
}

.ohuMpq_container .ohuMpq_header {
  background-color: var(--color-layer-1);
  border-radius: 16px;
  align-items: center;
  padding: 24px;
  display: flex;
}

.ohuMpq_container .ohuMpq_header .ohuMpq_icon {
  background-color: var(--color-layer-2);
  width: 60px;
  height: 60px;
  border-radius: 12px;
  margin-right: 24px;
  position: relative;
  overflow: hidden;
}

.ohuMpq_container .ohuMpq_header .ohuMpq_icon:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite ohuMpq_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.ohuMpq_container .ohuMpq_header .ohuMpq_wrapper {
  flex-direction: column;
  display: flex;
}

.ohuMpq_container .ohuMpq_header .ohuMpq_wrapper .ohuMpq_title {
  background-color: var(--color-layer-2);
  width: 113px;
  height: 28px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.ohuMpq_container .ohuMpq_header .ohuMpq_wrapper .ohuMpq_title:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite ohuMpq_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.ohuMpq_container .ohuMpq_header .ohuMpq_wrapper .ohuMpq_tags {
  background-color: var(--color-layer-2);
  width: 453px;
  height: 20px;
  border-radius: 8px;
  margin-top: 12px;
  position: relative;
  overflow: hidden;
}

.ohuMpq_container .ohuMpq_header .ohuMpq_wrapper .ohuMpq_tags:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite ohuMpq_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.ohuMpq_container .ohuMpq_header .ohuMpq_button {
  background-color: var(--color-layer-2);
  width: 158px;
  height: 44px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.ohuMpq_container .ohuMpq_header .ohuMpq_button:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite ohuMpq_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.ohuMpq_container .ohuMpq_content {
  width: 100%;
  background-color: var(--color-layer-1);
  border-radius: 16px;
  flex-direction: column;
  flex: 1;
  margin-top: 24px;
  padding: 24px;
  display: flex;
}

.ohuMpq_container .ohuMpq_content .ohuMpq_tabBar {
  background-color: var(--color-layer-2);
  width: 100%;
  height: 25px;
  border-radius: 8px;
  margin-bottom: 54px;
  position: relative;
  overflow: hidden;
}

.ohuMpq_container .ohuMpq_content .ohuMpq_tabBar:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite ohuMpq_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.ohuMpq_container .ohuMpq_content .ohuMpq_field {
  background-color: var(--color-layer-2);
  width: 566px;
  height: 44px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.ohuMpq_container .ohuMpq_content .ohuMpq_field:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite ohuMpq_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.ohuMpq_container .ohuMpq_content .ohuMpq_field + .ohuMpq_field {
  margin-top: 24px;
}

.ohuMpq_container .ohuMpq_content .ohuMpq_footer {
  justify-content: end;
  display: flex;
}

.ohuMpq_container .ohuMpq_content .ohuMpq_footer .ohuMpq_button {
  background-color: var(--color-layer-2);
  width: 194px;
  height: 44px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.ohuMpq_container .ohuMpq_content .ohuMpq_footer .ohuMpq_button:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite ohuMpq_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

@keyframes -TV4Uq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes -TV4Uq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.-TV4Uq_container {
  padding: 24px 32px;
  display: flex;
}

.-TV4Uq_introduction {
  width: 296px;
  flex-shrink: 0;
  margin-right: 56px;
  padding-bottom: 24px;
}

.-TV4Uq_introduction > :not(:first-child) {
  margin-top: 8px;
}

.-TV4Uq_introduction .-TV4Uq_title {
  color: var(--color-text-secondary);
  font: var(--font-subhead-cap);
  letter-spacing: .1em;
  text-transform: uppercase;
  color: var(--color-neutral-variant-60);
}

.-TV4Uq_introduction .-TV4Uq_description {
  font: var(--font-body-medium);
  color: var(--color-text-secondary);
}

.-TV4Uq_introduction .-TV4Uq_description a {
  color: var(--color-text-link);
  margin-left: 4px;
  text-decoration: none;
}

@media screen and (max-width: 1080px) {
  .-TV4Uq_container {
    flex-direction: column;
  }

  .-TV4Uq_container .-TV4Uq_introduction {
    width: 100%;
    margin-right: unset;
  }
}

.-TV4Uq_form {
  flex-grow: 1;
}

@keyframes xXo6qa_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes xXo6qa_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.xXo6qa_field:not(:first-child) {
  margin-top: 24px;
}

.xXo6qa_headline {
  align-items: center;
  margin-bottom: 4px;
  display: flex;
}

.xXo6qa_headline .xXo6qa_title {
  font: var(--font-subhead-2);
  color: var(--color-text);
}

.xXo6qa_headline .xXo6qa_icon {
  width: 16px;
  height: 16px;
  color: var(--color-text-secondary);
  margin-left: 4px;
}

.xXo6qa_headline .xXo6qa_required {
  font: var(--font-body-medium);
  color: var(--color-text-secondary);
}

@keyframes nPRKwa_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes nPRKwa_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.nPRKwa_linkButton {
  font: var(--font-body-medium);
  color: var(--color-text-link);
  -webkit-user-select: none;
  user-select: none;
  background: none;
  border-color: #0000;
  align-items: center;
  text-decoration: none;
  display: inline-flex;
}

.nPRKwa_linkButton > :not(:first-child) {
  margin-left: 4px;
}

.nPRKwa_linkButton:focus-visible {
  outline: 2px solid var(--color-focused-variant);
}

.nPRKwa_linkButton:disabled {
  color: var(--color-disabled);
  cursor: not-allowed;
}

.nPRKwa_linkButton:not(:disabled):hover {
  text-decoration: underline;
}

.nPRKwa_linkButton > svg {
  color: var(--color-text-link);
}

@keyframes Ww65cW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes Ww65cW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.Ww65cW_nav {
  border-bottom: 1px solid var(--color-surface-5);
  margin-top: 4px;
  display: flex;
}

@keyframes t5qcVa_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes t5qcVa_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.t5qcVa_item {
  align-items: center;
  display: flex;
}

.t5qcVa_item:not(:last-child) {
  margin-right: 24px;
}

.t5qcVa_item .t5qcVa_link {
  font: var(--font-subhead-2);
  border-radius: 4px;
  margin-bottom: 4px;
  padding: 2px 6px;
}

.t5qcVa_item .t5qcVa_link a {
  color: var(--color-text-secondary);
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
}

.t5qcVa_item .t5qcVa_link:hover {
  background-color: var(--color-hover-variant);
}

.t5qcVa_item .t5qcVa_selected {
  color: var(--color-text-link);
  position: relative;
}

.t5qcVa_item .t5qcVa_selected a {
  color: var(--color-text-link);
}

.t5qcVa_item .t5qcVa_selected:after {
  content: "";
  border-top: 2px solid var(--color-text-link);
  border-radius: 8px 8px 0 0;
  display: block;
  position: absolute;
  bottom: -5px;
  left: 0;
  right: 0;
}

.t5qcVa_item .t5qcVa_errors {
  font: var(--font-label-medium);
  color: var(--color-white);
  background-color: var(--color-error-50);
  vertical-align: middle;
  cursor: default;
  border-radius: 10px;
  margin-bottom: 4px;
  margin-left: 2px;
  padding: 2px 6px;
}

@keyframes Qtm6tW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes Qtm6tW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.Qtm6tW_container {
  min-height: 100%;
  min-width: min-content;
  flex-direction: column;
  display: flex;
}

.Qtm6tW_container > :not(:first-child) {
  margin-top: 16px;
}

.Qtm6tW_container .Qtm6tW_body {
  flex-direction: column;
  flex: 1;
  padding-bottom: 0;
  display: flex;
  position: relative;
}

.Qtm6tW_container .Qtm6tW_footer {
  background: var(--color-base);
  margin: 0 -24px;
  position: sticky;
  bottom: 0;
}

.Qtm6tW_container .Qtm6tW_footer .Qtm6tW_footerMain {
  background: var(--color-layer-1);
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
  justify-content: flex-end;
  padding: 24px;
  display: flex;
}

.Qtm6tW_container .Qtm6tW_footer:before {
  content: "";
  background: var(--color-border);
  opacity: .5;
  height: 1px;
  margin: 0;
  display: block;
}

@keyframes nfnr9a_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes nfnr9a_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.nfnr9a_backLink {
  margin: 4px 0 0 4px;
}

.nfnr9a_deleteConfirm > :not(:first-child) {
  margin-top: 24px;
}

.nfnr9a_deleteConfirm .nfnr9a_description {
  font: var(--font-body-medium);
}

.nfnr9a_deleteConfirm .nfnr9a_highlight {
  color: var(--color-primary-50);
}

.nfnr9a_header {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.nfnr9a_header .nfnr9a_info {
  display: flex;
}

.nfnr9a_header .nfnr9a_info .nfnr9a_icon {
  width: 60px;
  height: 60px;
  object-fit: cover;
  margin-left: 8px;
}

.nfnr9a_header .nfnr9a_info .nfnr9a_meta {
  flex-direction: column;
  justify-content: space-between;
  margin-left: 24px;
  display: flex;
}

.nfnr9a_header .nfnr9a_info .nfnr9a_meta .nfnr9a_name {
  font: var(--font-title-large);
  color: var(--color-text);
}

.nfnr9a_header .nfnr9a_operations {
  align-items: center;
  display: flex;
}

.nfnr9a_header .nfnr9a_operations .nfnr9a_moreIcon {
  color: var(--color-text-secondary);
}

.nfnr9a_header .nfnr9a_operations > :not(:first-child) {
  margin-left: 12px;
}

@keyframes mTaj3G_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes mTaj3G_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.mTaj3G_item {
  white-space: nowrap;
  align-items: center;
  display: flex;
}

.mTaj3G_item > div:not(:first-child) {
  margin-left: 12px;
}

.mTaj3G_item .mTaj3G_content {
  max-width: 100%;
  padding-right: 16px;
}

.mTaj3G_item .mTaj3G_content .mTaj3G_title {
  font: var(--font-body-medium);
  color: var(--color-text-link);
  text-decoration: none;
}

.mTaj3G_item .mTaj3G_content .mTaj3G_subtitle {
  font: var(--font-body-small);
  color: var(--color-text-secondary);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.mTaj3G_item.mTaj3G_compact .mTaj3G_content {
  align-items: baseline;
  display: flex;
}

.mTaj3G_item.mTaj3G_compact .mTaj3G_content .mTaj3G_title {
  margin-right: 4px;
}

@keyframes Rm7RFG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes Rm7RFG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.Rm7RFG_container {
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.Rm7RFG_positionInfo {
  font: var(--font-body-medium);
  color: var(--color-text-secondary);
}

.Rm7RFG_pagination {
  height: 28px;
  justify-content: right;
  margin: 0;
  padding-inline-start: 16px;
  display: flex;
}

.Rm7RFG_pagination li {
  list-style: none;
}

.Rm7RFG_pagination li:not(:first-child) {
  margin-left: 8px;
}

.Rm7RFG_pagination li .Rm7RFG_button {
  min-width: 28px;
  height: 28px;
  text-overflow: unset;
  border: 1px solid var(--color-border);
  border-radius: 6px;
  padding: 0 6px;
}

.Rm7RFG_pagination li .Rm7RFG_button > span {
  margin: 0 auto;
}

.Rm7RFG_pagination li .Rm7RFG_button.Rm7RFG_active {
  border-color: var(--color-text-link);
  color: var(--color-text-link);
}

.Rm7RFG_pagination li.Rm7RFG_disabled .Rm7RFG_button {
  cursor: not-allowed;
  background: var(--color-neutral-95);
}

.Rm7RFG_pagination li.Rm7RFG_disabled .Rm7RFG_button:hover {
  background: var(--color-neutral-95);
}

@keyframes su0mKq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes su0mKq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.su0mKq_tableEmptyTableData {
  border-bottom: unset;
}

.su0mKq_tableEmpty {
  flex-direction: column;
  align-items: center;
  padding: 16px 0;
  display: flex;
}

.su0mKq_tableEmpty .su0mKq_title {
  font: var(--font-subhead-2);
  margin-bottom: 8px;
}

.su0mKq_tableEmpty .su0mKq_content {
  font: var(--font-body-medium);
  color: var(--color-neutral-50);
  margin-bottom: 8px;
}

@keyframes NKlFWq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes NKlFWq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.NKlFWq_tableError {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.NKlFWq_tableError > :not(:first-child) {
  margin-top: 8px;
}

.NKlFWq_tableError .NKlFWq_title {
  text-align: center;
  font: var(--font-subhead-2);
}

.NKlFWq_tableError .NKlFWq_content {
  font: var(--font-body-medium);
  color: var(--color-neutral-50);
}

@keyframes BgyFAq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes BgyFAq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.BgyFAq_loading .BgyFAq_itemPreview {
  align-items: center;
  display: flex;
}

.BgyFAq_loading .BgyFAq_itemPreview .BgyFAq_avatar {
  background-color: var(--color-layer-2);
  width: 40px;
  height: 40px;
  border-radius: 12px;
  margin-right: 16px;
  position: relative;
  overflow: hidden;
}

.BgyFAq_loading .BgyFAq_itemPreview .BgyFAq_avatar:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite BgyFAq_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.BgyFAq_loading .BgyFAq_itemPreview .BgyFAq_content {
  width: 135px;
}

.BgyFAq_loading .BgyFAq_itemPreview .BgyFAq_content .BgyFAq_title {
  background-color: var(--color-layer-2);
  height: 12px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.BgyFAq_loading .BgyFAq_itemPreview .BgyFAq_content .BgyFAq_title:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite BgyFAq_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.BgyFAq_loading .BgyFAq_itemPreview .BgyFAq_content .BgyFAq_subTitle {
  background-color: var(--color-layer-2);
  height: 8px;
  border-radius: 8px;
  margin-top: 8px;
  position: relative;
  overflow: hidden;
}

.BgyFAq_loading .BgyFAq_itemPreview .BgyFAq_content .BgyFAq_subTitle:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite BgyFAq_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.BgyFAq_loading .BgyFAq_rect {
  background-color: var(--color-layer-2);
  height: 32px;
  max-width: 344px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.BgyFAq_loading .BgyFAq_rect:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite BgyFAq_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

@keyframes PxkItW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes PxkItW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.PxkItW_container {
  width: 100%;
  height: 100%;
  flex-direction: column;
  padding-bottom: 24px;
  display: flex;
}

.PxkItW_headline {
  justify-content: space-between;
  display: flex;
}

.PxkItW_table {
  flex: 1;
  margin-top: 16px;
  overflow: hidden;
}

@keyframes _5oeMfq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _5oeMfq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._5oeMfq_base {
  box-shadow: var(--shadow-1);
}

tr._5oeMfq_clickable {
  cursor: pointer;
}

tr._5oeMfq_clickable:hover {
  background: var(--color-hover);
}

._5oeMfq_scrollable {
  max-height: 100%;
  background-color: var(--color-layer-1);
  border-radius: 12px;
  padding-bottom: 8px;
  overflow-y: auto;
}

._5oeMfq_scrollable table {
  box-shadow: none;
  border: none;
}

._5oeMfq_scrollable table thead {
  background: var(--color-layer-1);
  position: sticky;
  top: 0;
}

._5oeMfq_scrollable table tbody tr:last-child td {
  border-bottom: unset;
}

._5oeMfq_empty {
  height: 100%;
}

@keyframes _2WfC1G_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _2WfC1G_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._2WfC1G_icon {
  flex-shrink: 0;
}

._2WfC1G_pagination {
  margin-top: 16px;
}

._2WfC1G_apiResourceName {
  width: 360px;
}

@keyframes rotating_a964f6 {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes shimmer_a964f6 {
  100% {
    transform: translateX(100%);
  }
}

.content_a964f6 {
  width: 50%;
  max-width: 900px;
  min-width: 770px;
  background: var(--color-base);
  outline: none;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.content_a964f6 .wrapper_a964f6 {
  height: 100%;
  flex-direction: column;
  display: flex;
}

.content_a964f6 .wrapper_a964f6 .header_a964f6 {
  background-color: var(--color-layer-1);
  height: 64px;
  flex-shrink: 0;
  align-items: center;
  padding: 0 24px;
  display: flex;
}

.content_a964f6 .wrapper_a964f6 .body_a964f6 {
  flex-direction: column;
  flex: 1;
  display: flex;
  overflow-y: auto;
}

.overlay_a964f6 {
  background: var(--color-drawer-overlay);
  position: fixed;
  inset: 0;
}

.ReactModal__Content[role="drawer"] {
  transition: transform .3s ease-in-out;
  transform: translateX(100%);
}

.ReactModal__Content--after-open[role="drawer"] {
  transform: translateX(0);
}

.ReactModal__Content--before-close[role="drawer"] {
  transform: translateX(100%);
}

@keyframes VVOXbG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes VVOXbG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.VVOXbG_container {
  background: #34353f;
  border-radius: 16px;
  padding: 24px;
  position: relative;
}

.VVOXbG_container .VVOXbG_copy {
  opacity: 0;
  z-index: 1;
  cursor: pointer;
  justify-content: right;
  align-items: center;
  transition: opacity .2s ease-in-out;
  display: flex;
  position: absolute;
  top: 24px;
  right: 24px;
}

.VVOXbG_container:hover .VVOXbG_copy {
  opacity: 1;
}

.VVOXbG_container .VVOXbG_editor {
  position: relative;
}

.VVOXbG_container .VVOXbG_editor textarea {
  width: 100%;
  height: 100%;
  white-space: pre-wrap;
  word-break: break-all;
  resize: none;
  color: #fefefe;
  -webkit-text-fill-color: transparent;
  background: none;
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  font-family: Roboto Mono, monospace;
  font-size: 14px;
  line-height: 1.5;
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.VVOXbG_container .VVOXbG_editor textarea, .VVOXbG_container .VVOXbG_editor pre {
  min-height: 20px;
  display: flex;
}

@keyframes PkjlTG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes PkjlTG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.PkjlTG_container {
  background-color: var(--color-layer-light);
  border-radius: 8px;
  flex-direction: column;
  margin: 24px 0;
  display: flex;
}

.PkjlTG_container .PkjlTG_summary {
  color: var(--color-text-secondary);
  font: var(--font-subhead-2);
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  align-items: center;
  padding: 16px;
  display: flex;
}

.PkjlTG_container .PkjlTG_summary .PkjlTG_arrow {
  margin-right: 8px;
  transition: transform .3s;
  transform: rotate(0);
}

.PkjlTG_container .PkjlTG_details {
  color: var(--color-text);
  font: var(--font-body-medium);
  border-top: 1px solid var(--color-divider);
  padding: 16px;
}

.PkjlTG_container .PkjlTG_details > p {
  margin: 0;
}

.PkjlTG_container.PkjlTG_expanded .PkjlTG_summary .PkjlTG_arrow {
  transform: rotate(90deg);
}

@keyframes _7zJXOa_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _7zJXOa_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._7zJXOa_header {
  background-color: var(--color-layer-1);
  height: 64px;
  align-items: center;
  padding: 0 24px;
  display: flex;
}

._7zJXOa_header ._7zJXOa_separator {
  border-left: 1px solid var(--color-border);
  width: 0;
  height: 20px;
  margin: 0 20px 0 16px;
}

._7zJXOa_header ._7zJXOa_closeIcon {
  color: var(--color-text-secondary);
}

._7zJXOa_header ._7zJXOa_githubIcon {
  margin-right: 16px;
}

._7zJXOa_header ._7zJXOa_githubIcon div {
  display: flex;
}

._7zJXOa_header ._7zJXOa_githubIcon svg {
  color: var(--color-text);
}

._7zJXOa_header ._7zJXOa_getSampleButton {
  margin: 0 60px 0 24px;
}

@keyframes BWvkSa_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes BWvkSa_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.BWvkSa_radioGroup input[type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  width: 0;
  height: 0;
  margin: 0;
  position: absolute;
}

.BWvkSa_card > .BWvkSa_radio {
  outline: 1px solid var(--color-neutral-90);
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  border: 1px solid #0000;
  border-radius: 16px;
  padding: 12px;
}

.BWvkSa_card > .BWvkSa_radio.BWvkSa_disabled {
  cursor: not-allowed;
  background-color: var(--color-layer-2);
  border-color: var(--color-layer-2);
  outline: unset;
}

.BWvkSa_card > .BWvkSa_radio:not(.BWvkSa_disabled):focus {
  outline: 1px solid var(--color-primary);
  box-shadow: var(--shadow-2);
}

.BWvkSa_card > .BWvkSa_radio:not(.BWvkSa_disabled):hover {
  box-shadow: var(--shadow-2);
}

.BWvkSa_card > .BWvkSa_radio .BWvkSa_content {
  position: relative;
}

.BWvkSa_card > .BWvkSa_radio .BWvkSa_content .BWvkSa_indicator {
  position: absolute;
  top: 0;
  right: 0;
}

.BWvkSa_card > .BWvkSa_radio .BWvkSa_content .BWvkSa_indicator svg {
  opacity: 0;
}

.BWvkSa_card > .BWvkSa_radio .BWvkSa_content .BWvkSa_disabledLabel {
  background: var(--color-neutral-90);
  font: var(--font-label-medium);
  color: var(--color-text);
  border-radius: 10px;
  padding: 2px 8px;
}

.BWvkSa_card > .BWvkSa_radio.BWvkSa_checked {
  border-color: var(--color-primary);
  outline: 1px solid var(--color-primary);
}

.BWvkSa_card > .BWvkSa_radio.BWvkSa_checked .BWvkSa_indicator svg {
  opacity: 1;
}

.BWvkSa_plain {
  font: var(--font-body-medium);
}

.BWvkSa_plain > .BWvkSa_radio {
  cursor: pointer;
}

.BWvkSa_plain > .BWvkSa_radio:not(:last-child) {
  margin-bottom: 8px;
}

.BWvkSa_plain > .BWvkSa_radio .BWvkSa_content {
  align-items: center;
  display: flex;
}

.BWvkSa_plain > .BWvkSa_radio .BWvkSa_content .BWvkSa_indicator {
  border: 2px solid var(--color-neutral-60);
  border-radius: 50%;
  margin-right: 8px;
  display: inline-block;
}

.BWvkSa_plain > .BWvkSa_radio .BWvkSa_content .BWvkSa_indicator:before {
  content: "";
  background: var(--color-layer-1);
  width: 10px;
  height: 10px;
  border: 2px solid var(--color-layer-1);
  border-radius: 50%;
  display: block;
}

.BWvkSa_plain > .BWvkSa_radio.BWvkSa_checked .BWvkSa_content .BWvkSa_indicator {
  border-color: var(--color-primary);
}

.BWvkSa_plain > .BWvkSa_radio.BWvkSa_checked .BWvkSa_content .BWvkSa_indicator:before {
  background: var(--color-primary);
}

.BWvkSa_plain > .BWvkSa_radio.BWvkSa_disabled {
  cursor: not-allowed;
  color: var(--color-disabled);
}

.BWvkSa_plain > .BWvkSa_radio.BWvkSa_disabled .BWvkSa_content .BWvkSa_indicator {
  border-color: var(--color-disabled);
}

.BWvkSa_plain > .BWvkSa_radio.BWvkSa_disabled .BWvkSa_content .BWvkSa_indicator:before {
  background: var(--color-layer-1);
}

@keyframes svvxza_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes svvxza_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.svvxza_select {
  background: var(--color-layer-1);
  border: 1px solid var(--color-border);
  font: var(--font-body-medium);
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 6px;
  outline: 3px solid #0000;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  transition-property: outline, border;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
  display: flex;
  position: relative;
  overflow: hidden;
}

.svvxza_select.svvxza_open {
  border-color: var(--color-primary);
  outline-color: var(--color-focused-variant);
}

.svvxza_select.svvxza_readOnly {
  background: var(--color-inverse-on-surface);
  color: var(--color-text);
  border-color: var(--color-border);
  cursor: default;
}

.svvxza_select.svvxza_error {
  border-color: var(--color-error);
}

.svvxza_select.svvxza_error.svvxza_open {
  outline-color: var(--color-danger-focused);
}

.svvxza_select .svvxza_icon {
  color: var(--color-text-secondary);
  margin-left: 12px;
  display: flex;
}

.svvxza_select .svvxza_clear {
  display: none;
}

.svvxza_select .svvxza_arrow svg {
  width: 20px;
  height: 20px;
}

.svvxza_select.svvxza_clearable:hover .svvxza_clear {
  display: block;
}

.svvxza_select.svvxza_clearable:hover .svvxza_arrow {
  display: none;
}

.svvxza_select.svvxza_small {
  height: 30px;
}

.svvxza_select.svvxza_medium {
  height: 32px;
}

.svvxza_select.svvxza_large {
  height: 36px;
}

@keyframes PJGclW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes PJGclW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.PJGclW_card {
  flex-direction: column;
  padding: 20px 24px;
  scroll-margin: 20px;
  display: block;
}

.PJGclW_card .PJGclW_congrats {
  width: 160px;
  height: 160px;
  margin: 4px auto 32px;
  display: block;
}

.PJGclW_card .PJGclW_congratsText {
  width: 100%;
}

.PJGclW_card .PJGclW_title {
  font: var(--font-title-large);
}

.PJGclW_card .PJGclW_subtitle {
  font: var(--font-body-medium);
  color: var(--color-text);
  margin-top: 12px;
}

.PJGclW_card .PJGclW_radioGroup {
  width: 100%;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 24px;
  margin-right: 0;
  display: flex;
}

.PJGclW_card .PJGclW_radio {
  width: 240px;
  max-width: unset;
  font: var(--font-subhead-2);
  border-radius: 8px;
  padding: 20px;
}

.PJGclW_card .PJGclW_select {
  background: var(--color-guide-dropdown-background);
  border-color: var(--color-guide-dropdown-border);
}

.PJGclW_card.PJGclW_folded {
  height: 56px;
  background: var(--color-neutral-variant-90);
  font: var(--font-body-medium);
  color: var(--color-text);
  border-radius: 8px;
  flex-direction: row;
  flex: 0 0 56px;
  align-items: center;
  padding: 0 16px;
  display: flex;
}

.PJGclW_card.PJGclW_folded .PJGclW_tada {
  margin: 0 16px 0 0;
}

.PJGclW_card .PJGclW_buttonWrapper {
  width: 100%;
  justify-content: flex-end;
  margin-top: 24px;
  display: flex;
}

@keyframes Q9BMNq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes Q9BMNq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.Q9BMNq_step {
  background-color: var(--color-layer-1);
  border-radius: 16px;
  align-items: center;
  padding: 20px 24px;
  display: flex;
}

.Q9BMNq_step .Q9BMNq_index {
  background-color: var(--color-layer-2);
  width: 28px;
  height: 28px;
  border-radius: 50%;
  margin-right: 16px;
  position: relative;
  overflow: hidden;
}

.Q9BMNq_step .Q9BMNq_index:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite Q9BMNq_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.Q9BMNq_step .Q9BMNq_wrapper {
  flex-direction: column;
  flex: 1;
  display: flex;
}

.Q9BMNq_step .Q9BMNq_wrapper .Q9BMNq_title {
  background-color: var(--color-layer-2);
  width: 140px;
  height: 24px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.Q9BMNq_step .Q9BMNq_wrapper .Q9BMNq_title:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite Q9BMNq_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.Q9BMNq_step .Q9BMNq_wrapper .Q9BMNq_subtitle {
  background-color: var(--color-layer-2);
  width: 400px;
  height: 20px;
  border-radius: 8px;
  margin-top: 4px;
  position: relative;
  overflow: hidden;
}

.Q9BMNq_step .Q9BMNq_wrapper .Q9BMNq_subtitle:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite Q9BMNq_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.Q9BMNq_step + .Q9BMNq_step {
  margin-top: 24px;
}

@keyframes pLdshq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes pLdshq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.pLdshq_container {
  background-color: var(--color-base);
  height: 100vh;
  flex-direction: column;
  display: flex;
}

.pLdshq_container .pLdshq_content {
  flex-direction: column;
  flex: 1;
  align-items: center;
  padding: 24px 24px 80px;
  display: flex;
  overflow-y: auto;
}

.pLdshq_container .pLdshq_content > * {
  max-width: 858px;
  width: 100%;
}

.pLdshq_container .pLdshq_content .pLdshq_banner {
  align-items: center;
  margin-bottom: 24px;
  display: flex;
}

.pLdshq_markdownContent {
  margin-top: 24px;
}

@keyframes ZDF5ia_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes ZDF5ia_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.ZDF5ia_switch {
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative;
}

.ZDF5ia_switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.ZDF5ia_switch .ZDF5ia_slider {
  cursor: pointer;
  background-color: var(--color-neutral-90);
  border-radius: 12px;
  transition: all .4s;
  position: absolute;
  inset: 0;
}

.ZDF5ia_switch .ZDF5ia_slider:before {
  content: "";
  height: 20px;
  width: 20px;
  background-color: #fff;
  border-radius: 50%;
  transition: all .4s;
  position: absolute;
  bottom: 2px;
  left: 2px;
  box-shadow: 0 3px 7px #0000001f;
}

.ZDF5ia_switch input:checked + .ZDF5ia_slider {
  background-color: var(--color-success-70);
}

.ZDF5ia_switch input:checked + .ZDF5ia_slider:before {
  transform: translateX(16px);
}

.ZDF5ia_wrapper {
  border: 1px solid var(--color-neutral-90);
  border-radius: 8px;
  align-items: center;
  padding: 16px;
  display: flex;
}

.ZDF5ia_wrapper .ZDF5ia_label {
  font: var(--font-body-medium);
  flex: 1;
  margin-right: 8px;
}

@keyframes ck2QpG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes ck2QpG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.ck2QpG_backLink {
  margin: 4px 0 0 4px;
}

.ck2QpG_deleteConfirm > :not(:first-child) {
  margin-top: 24px;
}

.ck2QpG_deleteConfirm .ck2QpG_description {
  font: var(--font-body-medium);
}

.ck2QpG_deleteConfirm .ck2QpG_highlight {
  color: var(--color-primary-50);
}

.ck2QpG_textField {
  width: 100%;
}

.ck2QpG_header {
  flex: 0;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  display: flex;
}

.ck2QpG_header > :not(:first-child) {
  margin-left: 24px;
}

.ck2QpG_header .ck2QpG_icon {
  width: 60px;
  height: 60px;
  margin-left: 8px;
}

.ck2QpG_header .ck2QpG_operations {
  align-items: center;
  display: flex;
}

.ck2QpG_header .ck2QpG_operations .ck2QpG_moreIcon {
  color: var(--color-text-secondary);
}

.ck2QpG_header .ck2QpG_operations > :not(:first-child) {
  margin-left: 12px;
}

.ck2QpG_header .ck2QpG_metadata {
  flex: 1;
}

.ck2QpG_header .ck2QpG_metadata .ck2QpG_name {
  font: var(--font-title-large);
  color: var(--color-text);
}

.ck2QpG_header .ck2QpG_metadata .ck2QpG_details {
  white-space: nowrap;
}

.ck2QpG_header .ck2QpG_metadata .ck2QpG_details > * {
  display: inline-block;
}

.ck2QpG_header .ck2QpG_metadata .ck2QpG_details > :not(:first-child) {
  margin-left: 8px;
}

.ck2QpG_header .ck2QpG_metadata .ck2QpG_details .ck2QpG_type {
  background-color: var(--color-surface-variant);
  color: var(--color-text);
  font: var(--font-label-medium);
  border-radius: 10px;
  padding: 2px 8px;
}

.ck2QpG_header .ck2QpG_metadata .ck2QpG_details .ck2QpG_text {
  font: var(--font-subhead-2);
  color: var(--color-text-secondary);
}

.ck2QpG_header .ck2QpG_metadata .ck2QpG_details .ck2QpG_verticalBar {
  border-left: 1px solid var(--color-border);
  width: 0;
  height: 12px;
}

@keyframes q8OBxa_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes q8OBxa_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.q8OBxa_title {
  font: var(--font-subhead-2);
  color: var(--color-text);
  padding-right: 24px;
}

.q8OBxa_subtitle, .q8OBxa_description {
  flex: 2;
  margin-top: 12px;
}

.q8OBxa_subtitle {
  font: var(--font-body-medium);
  color: var(--color-text);
}

.q8OBxa_description {
  font: var(--font-body-medium);
  color: var(--color-text-secondary);
}

@keyframes lMkVDq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes lMkVDq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.lMkVDq_radioGroup {
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  margin-top: 8px;
  display: grid;
}

@media screen and (max-width: 668px) {
  .lMkVDq_radioGroup {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 500px) {
  .lMkVDq_radioGroup {
    grid-template-columns: repeat(1, 1fr);
  }
}

.lMkVDq_error {
  font: var(--font-body-medium);
  color: var(--color-error);
  margin-top: 8px;
}

@keyframes _7_IiMW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _7_IiMW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._7_IiMW_icon {
  flex-shrink: 0;
}

._7_IiMW_pagination {
  margin-top: 16px;
}

._7_IiMW_applicationName {
  width: 360px;
}

.QCDwsq_link {
  color: var(--color-text-link);
  text-decoration: none;
}

@keyframes _3-KeZq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _3-KeZq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._3-KeZq_userName {
  font: var(--body-medium);
  color: var(--color-text);
}

._3-KeZq_userName ._3-KeZq_userId {
  font: var(--body-small);
  color: var(--color-text-secondary);
  margin-left: 4px;
}

._3-KeZq_userName ._3-KeZq_link {
  color: var(--color-text-link);
  text-decoration: none;
}

._1f0U4G_success {
  color: var(--color-success-60);
}

._1f0U4G_fail {
  color: var(--color-error-40);
}

._1f0U4G_icon {
  width: 60px;
  height: 60px;
}

._1f0U4G_label {
  color: var(--color-text-secondary);
  font: var(--font-body-medium);
  text-align: center;
}

@keyframes tI75Cq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes tI75Cq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.tI75Cq_backLink {
  margin: 4px 0 0 4px;
}

.tI75Cq_header {
  padding: 24px;
  display: flex;
}

.tI75Cq_header > :not(:first-child) {
  margin-left: 24px;
}

.tI75Cq_header .tI75Cq_content {
  flex: 1;
}

.tI75Cq_header .tI75Cq_content .tI75Cq_eventName {
  color: var(--color-text);
  font: var(--font-title-large);
}

.tI75Cq_header .tI75Cq_content .tI75Cq_basicInfo {
  flex-wrap: wrap;
  align-items: center;
  padding-top: 16px;
  display: flex;
}

.tI75Cq_header .tI75Cq_content .tI75Cq_basicInfo > * {
  width: 270px;
  margin: 0 16px 16px 0;
}

.tI75Cq_header .tI75Cq_content .tI75Cq_infoItem {
  color: var(--color-text);
  font: var(--font-body-medium);
}

.tI75Cq_header .tI75Cq_content .tI75Cq_infoItem .tI75Cq_label {
  color: var(--color-text-secondary);
  font: var(--font-subhead-2);
  margin-bottom: 8px;
}

.tI75Cq_body {
  margin-bottom: 24px;
}

.tI75Cq_body > :not(:first-child) {
  margin-top: 16px;
}

.tI75Cq_body .tI75Cq_main {
  padding-bottom: 16px;
}

@keyframes ji4gBq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes ji4gBq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.ji4gBq_eventName {
  white-space: nowrap;
  align-items: center;
  display: flex;
}

.ji4gBq_eventName .ji4gBq_icon {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  margin-right: 4px;
}

.ji4gBq_eventName .ji4gBq_icon.ji4gBq_success {
  color: var(--color-success-60);
}

.ji4gBq_eventName .ji4gBq_icon.ji4gBq_fail {
  color: var(--color-error-40);
}

.ji4gBq_eventName .ji4gBq_title {
  font: var(--font-body-medium);
  color: var(--color-text-link);
  text-decoration: none;
}

@keyframes _l4QRW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _l4QRW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._l4QRW_container {
  height: 100%;
  flex-direction: column;
  display: flex;
}

._l4QRW_filter {
  border-bottom: 1px solid var(--color-divider);
  background-color: var(--color-layer-1);
  border-radius: 12px 12px 0 0;
  justify-content: flex-end;
  align-items: center;
  padding: 12px;
  display: flex;
}

._l4QRW_filter ._l4QRW_title {
  color: var(--color-text-secondary);
  font: var(--font-body-medium);
}

._l4QRW_filter ._l4QRW_eventSelector {
  width: 300px;
  margin-left: 8px;
}

._l4QRW_filter ._l4QRW_applicationSelector {
  width: 250px;
  margin-left: 8px;
}

._l4QRW_tableLayout {
  max-height: 100%;
  flex-direction: column;
  flex: 1;
  display: flex;
  overflow-y: auto;
}

._l4QRW_tableLayout ._l4QRW_tableContainer {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

._l4QRW_pagination {
  margin-top: 16px;
}

._l4QRW_eventName {
  width: 360px;
}

@keyframes dvBGRa_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes dvBGRa_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.dvBGRa_markdown li {
  font: var(--font-body-medium);
}

.dvBGRa_markdown li ul, .dvBGRa_markdown li ol {
  padding-inline-start: 1ch;
}

.dvBGRa_markdown ul {
  padding-inline-start: 4ch;
}

.dvBGRa_markdown ul > li {
  margin-block: 8px;
  padding-inline-start: 4px;
}

.dvBGRa_markdown ol {
  padding-inline-start: 2ch;
}

.dvBGRa_markdown ol > li {
  margin-block: 12px;
  padding-inline-start: 4px;
}

.dvBGRa_markdown a {
  font: var(--font-body-medium);
  color: var(--color-text-link);
  text-decoration: none;
}

.dvBGRa_markdown a:hover {
  border-bottom: 1px solid var(--color-text-link);
}

.dvBGRa_markdown h1 {
  font: var(--font-title-large);
  margin: 24px 0;
}

.dvBGRa_markdown h2 {
  font: var(--font-title-medium);
  color: var(--color-text-secondary);
  margin: 24px 0 12px;
}

.dvBGRa_markdown p {
  font: var(--font-body-medium);
  margin: 12px 0;
}

.dvBGRa_markdown table + table {
  margin-top: 12px;
}

.dvBGRa_inlineCode {
  background: var(--color-layer-2);
  font: var(--font-body-medium);
  border-radius: 4px;
  padding: 4px;
}

@keyframes OSAXSW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes OSAXSW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.OSAXSW_status {
  font: var(--font-body-medium);
  align-items: center;
  display: flex;
}

.OSAXSW_status .OSAXSW_icon {
  width: 10px;
  height: 10px;
  background: var(--color-success-70);
  border-radius: 50%;
  margin-right: 8px;
}

.OSAXSW_status.OSAXSW_disabled .OSAXSW_icon {
  background: var(--color-neutral-70);
}

.OSAXSW_status.OSAXSW_outlined {
  background: var(--color-success-99);
  font: var(--font-label-medium);
  border-radius: 10px;
  padding: 2px 8px;
}

.OSAXSW_status.OSAXSW_outlined.OSAXSW_disabled {
  background: var(--color-neutral-variant-95);
}

@keyframes _8EI5Va_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _8EI5Va_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._8EI5Va_fields {
  align-items: flex-end;
  margin-bottom: 4px;
  display: flex;
}

._8EI5Va_fields ._8EI5Va_textField {
  width: 100%;
}

._8EI5Va_fields ._8EI5Va_send {
  margin-bottom: 1px;
  margin-left: 6px;
}

._8EI5Va_error {
  font: var(--font-body-medium);
  color: var(--color-error);
}

._8EI5Va_description {
  font: var(--font-body-medium);
  color: var(--color-text-secondary);
}

._8EI5Va_successfulTooltip {
  background-color: var(--color-success-60);
}

@keyframes _8ugH2W_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _8ugH2W_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._8ugH2W_container {
  background-color: var(--color-base);
  height: 100vh;
  flex-direction: column;
  display: flex;
}

._8ugH2W_container ._8ugH2W_header {
  background-color: var(--color-layer-1);
  height: 64px;
  align-items: center;
  padding: 0 84px 0 8px;
  display: flex;
}

._8ugH2W_container ._8ugH2W_header button {
  margin-left: 16px;
}

._8ugH2W_container ._8ugH2W_header ._8ugH2W_separator {
  border-left: 1px solid var(--color-border);
  width: 0;
  height: 20px;
  margin: 0 20px 0 16px;
}

._8ugH2W_container ._8ugH2W_header ._8ugH2W_closeIcon {
  color: var(--color-text-secondary);
}

._8ugH2W_container ._8ugH2W_content {
  flex: 1;
  display: flex;
  overflow: hidden;
}

._8ugH2W_container ._8ugH2W_content > * {
  flex: 1;
  overflow-y: auto;
}

._8ugH2W_container ._8ugH2W_content ._8ugH2W_readme {
  background-color: var(--color-layer-1);
  border-radius: 16px;
  margin: 24px 40px 24px 72px;
  padding: 0 24px;
}

._8ugH2W_container ._8ugH2W_content ._8ugH2W_setup {
  padding: 24px 72px 24px 0;
}

._8ugH2W_container ._8ugH2W_content form + div, ._8ugH2W_editor, ._8ugH2W_tester {
  margin-top: 24px;
}

@keyframes nTXhUq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes nTXhUq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.nTXhUq_platforms {
  margin-top: 24px;
}

.nTXhUq_platforms .nTXhUq_title {
  font: var(--font-subhead-2);
  margin-bottom: 12px;
}

@keyframes zQCxZG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes zQCxZG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.zQCxZG_body .zQCxZG_connectorGroup {
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  display: grid;
}

@media screen and (max-width: 850px) {
  .zQCxZG_body .zQCxZG_connectorGroup {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 668px) {
  .zQCxZG_body .zQCxZG_connectorGroup {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 500px) {
  .zQCxZG_body .zQCxZG_connectorGroup {
    grid-template-columns: repeat(1, 1fr);
  }
}

.zQCxZG_body .zQCxZG_connectorGroup.zQCxZG_medium {
  grid-template-columns: repeat(2, 1fr);
}

@media screen and (max-width: 500px) {
  .zQCxZG_body .zQCxZG_connectorGroup.zQCxZG_medium {
    grid-template-columns: repeat(1, 1fr);
  }
}

.zQCxZG_body .zQCxZG_connectorGroup.zQCxZG_large {
  grid-template-columns: repeat(3, 1fr);
}

@media screen and (max-width: 668px) {
  .zQCxZG_body .zQCxZG_connectorGroup.zQCxZG_large {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 500px) {
  .zQCxZG_body .zQCxZG_connectorGroup.zQCxZG_large {
    grid-template-columns: repeat(1, 1fr);
  }
}

.zQCxZG_body .zQCxZG_connectorGroup .zQCxZG_connector {
  font: var(--font-body-medium);
  display: flex;
}

.zQCxZG_body .zQCxZG_connectorGroup .zQCxZG_connector .zQCxZG_logo {
  width: 40px;
  height: 40px;
  align-items: center;
  margin-right: 12px;
  display: flex;
}

.zQCxZG_body .zQCxZG_connectorGroup .zQCxZG_connector .zQCxZG_logo img {
  width: 40px;
  height: 40px;
}

.zQCxZG_body .zQCxZG_connectorGroup .zQCxZG_connector .zQCxZG_content {
  flex: 1;
}

.zQCxZG_body .zQCxZG_connectorGroup .zQCxZG_connector .zQCxZG_content .zQCxZG_name {
  font: var(--font-subhead-2);
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.zQCxZG_body .zQCxZG_connectorGroup .zQCxZG_connector .zQCxZG_content .zQCxZG_name.zQCxZG_nameWithRightPadding {
  padding-right: 48px;
}

.zQCxZG_body .zQCxZG_connectorGroup .zQCxZG_connector .zQCxZG_content .zQCxZG_connectorId {
  font: var(--font-body-small);
  color: var(--color-text-secondary);
  margin-top: 4px;
}

.zQCxZG_body .zQCxZG_connectorGroup .zQCxZG_connector .zQCxZG_content .zQCxZG_description {
  font: var(--font-body-small);
  color: var(--color-text-secondary);
  -webkit-line-clamp: 4;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  margin-top: 4px;
  display: -webkit-box;
  overflow: hidden;
}

@keyframes ke03AG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes ke03AG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.ke03AG_backLink {
  margin: 4px 0 0 4px;
}

.ke03AG_header {
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  display: flex;
}

.ke03AG_header > :not(:first-child) {
  margin-left: 24px;
}

.ke03AG_header .ke03AG_logoContainer {
  width: 60px;
  height: 60px;
  background-color: var(--color-hover);
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
  display: flex;
}

.ke03AG_header .ke03AG_logo {
  width: 42px;
  height: 42px;
}

.ke03AG_header .ke03AG_operations {
  align-items: center;
  display: flex;
}

.ke03AG_header .ke03AG_operations .ke03AG_moreIcon {
  color: var(--color-text-secondary);
}

.ke03AG_header .ke03AG_operations > :not(:first-child) {
  margin-left: 8px;
}

.ke03AG_header .ke03AG_metadata {
  flex: 1;
}

.ke03AG_header .ke03AG_metadata > div {
  align-items: center;
  display: flex;
}

.ke03AG_header .ke03AG_metadata > div:not(:first-child) {
  margin-top: 8px;
}

.ke03AG_header .ke03AG_metadata > div > :not(:first-child) {
  margin-left: 8px;
}

.ke03AG_header .ke03AG_metadata .ke03AG_name {
  font: var(--font-title-large);
  color: var(--color-text);
}

.ke03AG_header .ke03AG_metadata .ke03AG_text {
  font: var(--font-subhead-2);
  color: var(--color-text-secondary);
}

.ke03AG_header .ke03AG_metadata .ke03AG_verticalBar {
  border-left: 1px solid var(--color-border);
  width: 0;
  height: 12px;
}

.ke03AG_codeEditor {
  margin-bottom: 24px;
}

.ke03AG_senderTest {
  margin-top: 24px;
}

.ke03AG_resetIcon {
  color: var(--color-text-secondary);
}

.ke03AG_readme {
  background-color: var(--color-layer-1);
  border-radius: 16px;
  margin: 24px;
  padding: 0 24px;
}

@keyframes -rCXFa_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes -rCXFa_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.-rCXFa_tabs {
  align-items: center;
  display: flex;
}

.-rCXFa_tabs .-rCXFa_tab {
  font: var(--font-subhead-2);
  color: var(--color-text);
  background: var(--color-layer-1);
  border: 1px solid var(--color-surface-5);
  border-radius: 100px;
  align-items: center;
  padding: 6px 12px;
  text-decoration: none;
  display: flex;
}

.-rCXFa_tabs .-rCXFa_tab:not(:last-child) {
  margin-right: 16px;
}

.-rCXFa_tabs .-rCXFa_tab .-rCXFa_icon {
  width: 16px;
  height: 16px;
  object-fit: cover;
  color: var(--color-text-link);
  margin-right: 4px;
}

.-rCXFa_tabs .-rCXFa_tab.-rCXFa_active {
  background: var(--color-inverse-primary);
  color: var(--color-white);
  border-color: var(--color-inverse-primary);
}

.-rCXFa_tabs .-rCXFa_tab.-rCXFa_active .-rCXFa_icon {
  color: var(--color-button-icon);
}

@keyframes Y_Pa3G_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes Y_Pa3G_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.Y_Pa3G_connectorType {
  background: var(--color-surface-variant);
  color: var(--color-text);
  font: var(--font-label-medium);
  border-radius: 10px;
  padding: 2px 8px;
}

@keyframes _2mHiHa_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _2mHiHa_shimmer {
  100% {
    transform: translateX(100%);
  }
}

a._2mHiHa_link {
  color: inherit;
  text-decoration: none;
}

._2mHiHa_logoContainer {
  width: 40px;
  height: 40px;
  background-color: var(--color-hover);
  border-radius: 8px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  display: flex;
}

._2mHiHa_logo {
  width: 28px;
  height: 28px;
}

._2mHiHa_previewTitle {
  align-items: center;
  display: flex;
}

._2mHiHa_previewTitle > :first-child {
  margin-right: 8px;
}

._2mHiHa_platforms, ._2mHiHa_platforms ._2mHiHa_platform {
  align-items: center;
  display: flex;
}

._2mHiHa_platforms ._2mHiHa_platform:not(:last-child):after {
  content: "";
  width: 0;
  height: 12px;
  border-right: 1px solid var(--color-border);
  margin: 0 4px;
}

._2mHiHa_platforms ._2mHiHa_platform svg {
  margin-right: 4px;
}

@keyframes W03tPW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes W03tPW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.W03tPW_content {
  box-shadow: var(--shadow-2);
  position: absolute;
}

.W03tPW_content:focus {
  outline: none;
}

.W03tPW_overlay {
  background: none;
  position: fixed;
  inset: 0;
}

@keyframes WG8Pea_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes WG8Pea_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.WG8Pea_field {
  align-items: center;
  display: flex;
}

.WG8Pea_tipIcon {
  margin-left: 4px;
}

.WG8Pea_tipIcon > svg {
  cursor: pointer;
  display: block;
}

.WG8Pea_title {
  font: var(--font-label-large);
}

.WG8Pea_content {
  font: var(--font-body-medium);
}

.WG8Pea_content a {
  color: #cabeff;
  text-decoration: none;
}

@keyframes _3oYvha_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _3oYvha_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._3oYvha_container ._3oYvha_notice {
  margin: 16px 0 0;
}

._3oYvha_container a {
  color: var(--color-text-link);
  text-decoration: none;
}

@keyframes G05ORa_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes G05ORa_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.G05ORa_container .G05ORa_tabs {
  margin-top: 16px;
}

.G05ORa_container .G05ORa_connectorName {
  width: 360px;
}

.G05ORa_container .G05ORa_empty {
  text-align: center;
  font-size: var(--font-body-medium);
}

.G05ORa_container .G05ORa_emptyLine {
  padding: 8px 0;
}

@keyframes bk57eW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes bk57eW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.bk57eW_number {
  font: var(--font-headline-small);
}

.bk57eW_delta {
  font: var(--font-title-medium);
  color: var(--color-success-50);
  align-items: center;
  display: flex;
}

.bk57eW_delta.bk57eW_down {
  color: var(--color-error-50);
}

.bk57eW_block {
  flex: 1;
}

.bk57eW_block:not(:last-child) {
  margin-right: 16px;
}

.bk57eW_block.bk57eW_bordered {
  border: 1px solid var(--color-divider);
  width: 360px;
  flex: unset;
}

.bk57eW_block .bk57eW_title {
  font: var(--font-title-medium);
  align-items: center;
  margin-bottom: 24px;
  display: flex;
}

.bk57eW_block .bk57eW_title .bk57eW_icon {
  width: 16px;
  height: 16px;
  color: var(--color-text-secondary);
  margin-left: 4px;
}

.bk57eW_block .bk57eW_title .bk57eW_icon > svg {
  display: block;
}

.bk57eW_block .bk57eW_content {
  align-items: baseline;
  display: flex;
}

.bk57eW_block .bk57eW_content .bk57eW_number {
  flex: 1;
}

.bk57eW_block.bk57eW_plain {
  padding: 0;
}

.bk57eW_block.bk57eW_plain .bk57eW_title {
  font: var(--font-title-medium);
  margin-bottom: 24px;
}

.bk57eW_block.bk57eW_plain .bk57eW_content {
  align-items: center;
  display: flex;
}

.bk57eW_block.bk57eW_plain .bk57eW_content .bk57eW_number {
  flex: 0;
  margin-right: 8px;
}

@keyframes hGh_LW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes hGh_LW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.hGh_LW_chartTooltip {
  background: var(--color-float);
  border: 1px solid var(--color-divider);
  box-shadow: var(--shadow-2);
  text-align: center;
  border-radius: 4px;
  padding: 8px 12px;
}

.hGh_LW_chartTooltip .hGh_LW_value {
  font: var(--font-subhead-2);
  color: var(--color-text);
}

.hGh_LW_chartTooltip .hGh_LW_label {
  font: var(--font-body-small);
  color: var(--color-text-secondary);
}

@keyframes grVxOq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes grVxOq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.grVxOq_title {
  background-color: var(--color-layer-2);
  height: 24px;
  border-radius: 8px;
  margin-bottom: 24px;
  position: relative;
  overflow: hidden;
}

.grVxOq_title:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite grVxOq_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.grVxOq_number {
  background-color: var(--color-layer-2);
  height: 32px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.grVxOq_number:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite grVxOq_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.grVxOq_blocks {
  align-items: center;
  margin-bottom: 16px;
  display: flex;
}

.grVxOq_block {
  flex: 1;
}

.grVxOq_block:not(:last-child) {
  margin-right: 16px;
}

.grVxOq_dau {
  width: 300px;
}

.grVxOq_curve {
  background-color: var(--color-layer-2);
  height: 168px;
  border-radius: 8px;
  margin: 40px 0 24px;
  position: relative;
  overflow: hidden;
}

.grVxOq_curve:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite grVxOq_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.grVxOq_activeBlocks {
  align-items: center;
  display: flex;
}

.grVxOq_activeBlocks .grVxOq_block {
  border: 1px solid var(--color-divider);
  width: 360px;
  flex: unset;
}

@keyframes aADheW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes aADheW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.aADheW_container {
  flex-direction: column;
  display: flex;
  overflow-y: auto;
}

.aADheW_header {
  margin-bottom: 24px;
}

.aADheW_header .aADheW_title {
  font: var(--font-title-large);
  color: var(--color-text);
}

.aADheW_header .aADheW_subtitle {
  font: var(--font-body-medium);
  color: var(--color-text-secondary);
  margin-top: 4px;
  padding-right: 24px;
}

.aADheW_blocks {
  align-items: center;
  margin-bottom: 16px;
  display: flex;
}

.aADheW_activeCard {
  position: relative;
}

.aADheW_activeCard .aADheW_datePicker {
  width: 160px;
  position: absolute;
  top: 24px;
  right: 24px;
}

.aADheW_curve {
  width: 100%;
  height: 168px;
  margin: 40px 0 24px;
}

@keyframes IgTfaG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes IgTfaG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.IgTfaG_card {
  background-color: var(--color-layer-1);
  border-radius: 16px;
  padding: 24px 32px;
  display: flex;
}

.IgTfaG_card .IgTfaG_icon {
  background-color: var(--color-layer-2);
  width: 48px;
  height: 48px;
  border-radius: 8px;
  margin-right: 24px;
  position: relative;
  overflow: hidden;
}

.IgTfaG_card .IgTfaG_icon:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite IgTfaG_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.IgTfaG_card .IgTfaG_wrapper {
  flex-direction: column;
  flex: 1;
  display: flex;
}

.IgTfaG_card .IgTfaG_wrapper .IgTfaG_title {
  background-color: var(--color-layer-2);
  width: 113px;
  height: 20px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.IgTfaG_card .IgTfaG_wrapper .IgTfaG_title:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite IgTfaG_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.IgTfaG_card .IgTfaG_wrapper .IgTfaG_subtitle {
  background-color: var(--color-layer-2);
  width: 453px;
  height: 20px;
  border-radius: 8px;
  margin-top: 4px;
  position: relative;
  overflow: hidden;
}

.IgTfaG_card .IgTfaG_wrapper .IgTfaG_subtitle:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite IgTfaG_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.IgTfaG_card .IgTfaG_button {
  background-color: var(--color-layer-2);
  width: 129px;
  height: 44px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.IgTfaG_card .IgTfaG_button:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite IgTfaG_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.IgTfaG_card + .IgTfaG_card {
  margin-top: 16px;
}

@keyframes vUugRG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes vUugRG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.vUugRG_container {
  flex-direction: column;
  display: flex;
  overflow-y: auto;
}

.vUugRG_header {
  margin-bottom: 24px;
}

.vUugRG_header .vUugRG_title {
  font: var(--font-title-large);
  color: var(--color-text);
}

.vUugRG_header .vUugRG_subtitle {
  font: var(--font-body-medium);
  color: var(--color-text-secondary);
  align-items: center;
  margin-top: 4px;
  padding-right: 24px;
  display: flex;
}

.vUugRG_header .vUugRG_subtitle .vUugRG_hideButton {
  display: inline-block;
}

.vUugRG_card {
  padding: 24px 32px;
  display: flex;
  position: relative;
}

.vUugRG_card .vUugRG_icon {
  width: 48px;
  height: 48px;
  margin-right: 24px;
}

.vUugRG_card .vUugRG_wrapper {
  flex-direction: column;
  flex: 1;
  display: flex;
}

.vUugRG_card .vUugRG_wrapper .vUugRG_title {
  font: var(--font-title-medium);
  color: var(--color-text);
}

.vUugRG_card .vUugRG_wrapper .vUugRG_subtitle {
  font: var(--font-body-medium);
  color: var(--color-text-secondary);
}

.vUugRG_card .vUugRG_button {
  min-width: 120px;
}

.vUugRG_card + .vUugRG_card {
  margin-top: 16px;
}

@keyframes _8LEt0W_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _8LEt0W_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._8LEt0W_container {
  height: 100%;
  color: var(--color-text);
  text-align: center;
  overflow: hidden;
}

._8LEt0W_container svg {
  margin: 15% 0 16px;
}

._8LEt0W_container ._8LEt0W_message {
  font: var(--font-body-medium);
}

@keyframes o4KLCG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes o4KLCG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.o4KLCG_changePassword {
  border: 1px solid var(--color-divider);
  width: 100%;
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  display: flex;
}

.o4KLCG_changePassword .o4KLCG_description {
  font: var(--font-body-medium);
  color: var(--color-text);
  margin-right: 16px;
}

@keyframes _3EUmTq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _3EUmTq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._3EUmTq_container {
  height: 100%;
  flex-direction: column;
  display: flex;
}

._3EUmTq_container ._3EUmTq_cardTitle {
  flex-shrink: 0;
}

._3EUmTq_container > :not(:first-child) {
  margin-top: 16px;
}

@keyframes R5BQyG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes R5BQyG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.R5BQyG_preview {
  min-width: 480px;
  background: var(--color-layer-1);
  border-radius: 16px;
  flex-direction: column;
  display: flex;
  overflow: hidden;
}

.R5BQyG_preview .R5BQyG_header {
  margin: 24px 24px 4px;
  display: flex;
}

.R5BQyG_preview .R5BQyG_header .R5BQyG_title {
  font: var(--font-subhead-1);
  flex: 1;
}

.R5BQyG_preview .R5BQyG_header .R5BQyG_selects {
  align-items: center;
  display: flex;
}

.R5BQyG_preview .R5BQyG_header .R5BQyG_selects > * {
  width: 104px;
}

.R5BQyG_preview .R5BQyG_header .R5BQyG_selects > :first-child {
  margin-right: 8px;
}

.R5BQyG_preview .R5BQyG_nav {
  border-bottom: unset;
  margin-top: 24px;
  padding: 0 24px 1px;
}

.R5BQyG_preview .R5BQyG_body {
  background: var(--color-surface-variant);
  flex: 1;
}

.R5BQyG_preview .R5BQyG_body iframe {
  border: none;
}

.R5BQyG_preview .R5BQyG_body.R5BQyG_web .R5BQyG_device {
  width: 480px;
  height: 380px;
  background: var(--color-surface-1);
  margin: 0 auto;
  position: relative;
}

.R5BQyG_preview .R5BQyG_body.R5BQyG_web .R5BQyG_device iframe {
  width: 960px;
  height: 760px;
  background: var(--color-surface-1);
  position: absolute;
  top: -190px;
  left: -240px;
  transform: scale(.5);
}

.R5BQyG_preview .R5BQyG_body.R5BQyG_mobile {
  height: 500px;
  padding: 40px 0;
  position: relative;
}

.R5BQyG_preview .R5BQyG_body.R5BQyG_mobile .R5BQyG_deviceWrapper {
  width: 390px;
  height: 450px;
  transform-origin: top;
  margin: 0 auto;
  transform: scale(.5);
}

.R5BQyG_preview .R5BQyG_body.R5BQyG_mobile .R5BQyG_deviceWrapper .R5BQyG_device {
  border-radius: 26px;
  overflow: hidden;
}

.R5BQyG_preview .R5BQyG_body.R5BQyG_mobile .R5BQyG_deviceWrapper .R5BQyG_device .R5BQyG_topBar {
  align-items: center;
  padding: 12px 24px;
  display: flex;
}

.R5BQyG_preview .R5BQyG_body.R5BQyG_mobile .R5BQyG_deviceWrapper .R5BQyG_device .R5BQyG_topBar .R5BQyG_time {
  font: var(--font-subhead-2);
  flex: 1;
}

.R5BQyG_preview .R5BQyG_body.R5BQyG_mobile .R5BQyG_deviceWrapper .R5BQyG_device.R5BQyG_dark {
  background: #1a1c1d;
}

.R5BQyG_preview .R5BQyG_body.R5BQyG_mobile .R5BQyG_deviceWrapper .R5BQyG_device.R5BQyG_dark .R5BQyG_topBar {
  color: #fff;
}

.R5BQyG_preview .R5BQyG_body.R5BQyG_mobile .R5BQyG_deviceWrapper .R5BQyG_device.R5BQyG_light {
  background: #fff;
}

.R5BQyG_preview .R5BQyG_body.R5BQyG_mobile .R5BQyG_deviceWrapper .R5BQyG_device.R5BQyG_light .R5BQyG_topBar {
  color: #000;
}

.R5BQyG_preview .R5BQyG_body.R5BQyG_mobile .R5BQyG_deviceWrapper .R5BQyG_device iframe {
  width: 390px;
  height: 808px;
}

@media screen and (min-height: 1100px) {
  .R5BQyG_preview .R5BQyG_body.R5BQyG_mobile .R5BQyG_deviceWrapper {
    transform: unset;
    height: 900px;
  }
}

@media screen and (min-height: 900px) and (max-height: 1100px) {
  .R5BQyG_preview .R5BQyG_body.R5BQyG_mobile .R5BQyG_deviceWrapper {
    height: 675px;
    transform: scale(.75);
  }
}

@keyframes KT-IsW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes KT-IsW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.KT-IsW_title {
  font: var(--font-title-small);
}

.KT-IsW_list {
  padding-left: 24px;
}

.KT-IsW_red {
  background-color: #dd37304d;
}

.KT-IsW_green {
  background-color: #68be6c66;
}

@keyframes _8_PeAG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _8_PeAG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._8_PeAG_container {
  min-width: 552px;
}

._8_PeAG_description {
  font: var(--font-body-medium);
}

._8_PeAG_content {
  font: var(--font-body-medium);
  border-radius: 8px;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  column-gap: 12px;
  margin-top: 12px;
  display: flex;
}

._8_PeAG_content ._8_PeAG_section {
  background: var(--color-layer-2);
  color: var(--color-text);
  border-radius: 8px;
  flex: 1;
  padding: 20px;
}

._8_PeAG_content ._8_PeAG_section ._8_PeAG_title {
  font: var(--font-title-medium);
  margin: 4px 0;
}

@keyframes H0j30G_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes H0j30G_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.H0j30G_container {
  min-width: 950px;
  height: 100%;
  align-items: stretch;
  display: flex;
}

.H0j30G_container > * {
  background: var(--color-layer-1);
  border-radius: 16px;
  flex-direction: column;
  display: flex;
}

.H0j30G_container .H0j30G_setup {
  height: 100%;
  flex: 1;
  margin-right: 12px;
  padding: 24px;
}

.H0j30G_container .H0j30G_setup .H0j30G_title {
  width: 113px;
  height: 20px;
  background-color: var(--color-layer-2);
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.H0j30G_container .H0j30G_setup .H0j30G_title:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite H0j30G_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.H0j30G_container .H0j30G_setup .H0j30G_subtitle {
  width: 177px;
  height: 16px;
  background-color: var(--color-layer-2);
  border-radius: 8px;
  margin-top: 16px;
  position: relative;
  overflow: hidden;
}

.H0j30G_container .H0j30G_setup .H0j30G_subtitle:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite H0j30G_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.H0j30G_container .H0j30G_setup .H0j30G_tabs {
  width: 498px;
  height: 25px;
  background-color: var(--color-layer-2);
  border-radius: 8px;
  margin: 24px 0 62px;
  position: relative;
  overflow: hidden;
}

.H0j30G_container .H0j30G_setup .H0j30G_tabs:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite H0j30G_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.H0j30G_container .H0j30G_setup .H0j30G_field {
  width: 100%;
  height: 44px;
  background-color: var(--color-layer-2);
  border-radius: 8px;
  margin-top: 24px;
  position: relative;
  overflow: hidden;
}

.H0j30G_container .H0j30G_setup .H0j30G_field:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite H0j30G_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.H0j30G_container .H0j30G_setup .H0j30G_footer {
  width: 100%;
  justify-content: right;
  display: flex;
}

.H0j30G_container .H0j30G_setup .H0j30G_footer .H0j30G_button {
  width: 194px;
  height: 44px;
  background-color: var(--color-layer-2);
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.H0j30G_container .H0j30G_setup .H0j30G_footer .H0j30G_button:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite H0j30G_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.H0j30G_container .H0j30G_preview {
  width: 480px;
  overflow: hidden;
}

.H0j30G_container .H0j30G_preview .H0j30G_header {
  width: 100%;
  height: 103px;
  padding: 24px;
  display: flex;
}

.H0j30G_container .H0j30G_preview .H0j30G_header .H0j30G_info .H0j30G_title {
  width: 113px;
  height: 20px;
  background-color: var(--color-layer-2);
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.H0j30G_container .H0j30G_preview .H0j30G_header .H0j30G_info .H0j30G_title:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite H0j30G_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.H0j30G_container .H0j30G_preview .H0j30G_header .H0j30G_info .H0j30G_subtitle {
  width: 177px;
  height: 16px;
  background-color: var(--color-layer-2);
  border-radius: 8px;
  margin-top: 16px;
  position: relative;
  overflow: hidden;
}

.H0j30G_container .H0j30G_preview .H0j30G_header .H0j30G_info .H0j30G_subtitle:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite H0j30G_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.H0j30G_container .H0j30G_preview .H0j30G_header .H0j30G_button {
  width: 104px;
  height: 30px;
  background-color: var(--color-layer-2);
  border-radius: 8px;
  margin-left: 10px;
  position: relative;
  overflow: hidden;
}

.H0j30G_container .H0j30G_preview .H0j30G_header .H0j30G_button:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite H0j30G_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.H0j30G_container .H0j30G_preview .H0j30G_content {
  background: var(--color-surface-variant);
}

.H0j30G_container .H0j30G_preview .H0j30G_content .H0j30G_mobile {
  width: 300px;
  height: 649px;
  background: var(--color-surface);
  transform-origin: top;
  border-radius: 16px;
  flex-direction: column;
  align-items: center;
  margin: 40px auto 0;
  padding: 24px;
  display: flex;
  transform: scale(.65);
}

.H0j30G_container .H0j30G_preview .H0j30G_content .H0j30G_mobile .H0j30G_logo {
  width: 64px;
  height: 64px;
  background-color: var(--color-layer-2);
  border-radius: 8px;
  margin-top: 64px;
  position: relative;
  overflow: hidden;
}

.H0j30G_container .H0j30G_preview .H0j30G_content .H0j30G_mobile .H0j30G_logo:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite H0j30G_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.H0j30G_container .H0j30G_preview .H0j30G_content .H0j30G_mobile .H0j30G_slogan {
  width: 177px;
  height: 16px;
  background-color: var(--color-layer-2);
  border-radius: 8px;
  margin: 12px 0 40px;
  position: relative;
  overflow: hidden;
}

.H0j30G_container .H0j30G_preview .H0j30G_content .H0j30G_mobile .H0j30G_slogan:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite H0j30G_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.H0j30G_container .H0j30G_preview .H0j30G_content .H0j30G_mobile .H0j30G_field {
  width: 100%;
  height: 36px;
  background-color: var(--color-layer-2);
  border-radius: 8px;
  margin-top: 12px;
  position: relative;
  overflow: hidden;
}

.H0j30G_container .H0j30G_preview .H0j30G_content .H0j30G_mobile .H0j30G_field:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite H0j30G_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.H0j30G_container .H0j30G_preview .H0j30G_content .H0j30G_mobile .H0j30G_button {
  width: 100%;
  height: 36px;
  background-color: var(--color-layer-2);
  border-radius: 8px;
  margin-top: 40px;
  position: relative;
  overflow: hidden;
}

.H0j30G_container .H0j30G_preview .H0j30G_content .H0j30G_mobile .H0j30G_button:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite H0j30G_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.H0j30G_container .H0j30G_preview .H0j30G_content .H0j30G_mobile .H0j30G_social {
  width: 180px;
  height: 24px;
  background-color: var(--color-layer-2);
  border-radius: 8px;
  margin-top: 12px;
  position: relative;
  overflow: hidden;
}

.H0j30G_container .H0j30G_preview .H0j30G_content .H0j30G_mobile .H0j30G_social:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite H0j30G_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

@keyframes j3eHoW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes j3eHoW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.j3eHoW_tabContent > :not(:first-child) {
  margin-top: 12px;
}

.j3eHoW_title {
  color: var(--color-text-secondary);
  font: var(--font-subhead-cap);
  letter-spacing: .1em;
  text-transform: uppercase;
  color: var(--color-neutral-variant-60);
}

.j3eHoW_formFieldDescription {
  font: var(--font-body-medium);
  color: var(--color-text-secondary);
  margin: 4px 0 8px;
}

.j3eHoW_socialOnlyDescription {
  color: var(--color-text-secondary);
  margin-left: 4px;
}

.j3eHoW_selections > :not(:first-child) {
  margin-top: 12px;
}

.j3eHoW_primaryTag {
  color: var(--color-text-secondary);
}

.j3eHoW_method {
  margin-top: 12px;
}

.j3eHoW_primarySocial {
  margin-top: 8px;
}

.j3eHoW_darkModeTip {
  font: var(--font-body-medium);
  color: var(--color-text-secondary);
  align-items: baseline;
  margin-top: 4px;
  display: flex;
}

.j3eHoW_manageLanguageButton {
  margin-top: 4px;
}

.j3eHoW_defaultLanguageDescription {
  font: var(--font-body-medium);
  color: var(--color-text-secondary);
  padding-top: 8px;
}

@keyframes Lxs6kq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes Lxs6kq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.Lxs6kq_container {
  border: 1px solid var(--color-border);
  font: var(--font-body-medium);
  border-radius: 6px;
  outline: 3px solid #0000;
  align-items: center;
  padding: 8px 12px;
  transition-property: outline, border;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
  display: flex;
  position: relative;
}

.Lxs6kq_container:focus-within {
  border-color: var(--color-primary);
  outline-color: var(--color-focused-variant);
}

.Lxs6kq_container input {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  padding: 0;
  overflow: hidden;
}

.Lxs6kq_container input::-moz-color-swatch-wrapper {
  width: 24px;
  height: 24px;
  padding: 0;
}

.Lxs6kq_container input::-webkit-color-swatch-wrapper {
  width: 24px;
  height: 24px;
  padding: 0;
}

.Lxs6kq_container input::-moz-color-swatch {
  border-style: none;
}

.Lxs6kq_container input::-webkit-color-swatch {
  border-style: none;
}

.Lxs6kq_container label {
  align-items: center;
  margin-left: 8px;
  display: flex;
  position: relative;
}

@keyframes IuyFQG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes IuyFQG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.IuyFQG_container {
  border: 1px solid var(--color-border);
  border-radius: 6px;
  outline: 3px solid #0000;
  padding: 8px 12px;
}

.IuyFQG_container:focus-within {
  border-color: var(--color-primary);
  outline-color: var(--color-focused-variant);
}

.IuyFQG_container textarea {
  width: 100%;
  height: 100%;
  color: var(--color-text);
  font: var(--font-body-medium);
  resize: none;
  background: none;
  border: none;
  outline: none;
  padding: 0;
}

.IuyFQG_container textarea::placeholder {
  color: var(--color-caption);
}

@keyframes BfWyiq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes BfWyiq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.BfWyiq_sectionTitle {
  color: var(--color-text-secondary);
  font: var(--font-subhead-cap);
  letter-spacing: .1em;
  text-transform: uppercase;
  font: var(--font-subhead-cap-small);
  color: var(--color-neutral-variant-60);
  background-color: var(--color-layer-light);
  padding: 4px 0;
}

.BfWyiq_sectionDataKey {
  font: var(--font-body-medium);
  color: var(--color-text);
  padding: 16px 20px;
}

.BfWyiq_sectionBuiltInText {
  border: 1px solid var(--color-border);
  color: var(--color-text);
  background: var(--color-layer-2);
  border-radius: 6px;
  padding: 8px 12px;
}

.BfWyiq_inputCell {
  position: relative;
}

.BfWyiq_sectionInputArea {
  position: absolute;
  inset: 8px 20px;
}

@keyframes aEWZJW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes aEWZJW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.aEWZJW_languageDetails {
  flex-grow: 1;
}

.aEWZJW_languageDetails .aEWZJW_title {
  font: var(--font-title-large);
  color: var(--color-text);
  justify-content: space-between;
  padding: 24px 20px;
  display: flex;
}

.aEWZJW_languageDetails .aEWZJW_title .aEWZJW_languageInfo {
  align-items: center;
  display: flex;
}

.aEWZJW_languageDetails .aEWZJW_title .aEWZJW_languageInfo > span {
  font: var(--font-body-medium);
  color: var(--color-text-secondary);
  margin-left: 8px;
}

.aEWZJW_languageDetails .aEWZJW_title .aEWZJW_languageInfo .aEWZJW_builtInFlag {
  font: var(--font-label-medium);
  color: var(--color-text);
  background-color: var(--color-surface-variant);
  border-radius: 10px;
  padding: 2px 8px;
  display: inline-block;
}

.aEWZJW_languageDetails .aEWZJW_content {
  border-top: 1px solid var(--color-divider);
  height: 481px;
  overflow-y: auto;
}

.aEWZJW_languageDetails .aEWZJW_content > table {
  border: none;
}

.aEWZJW_languageDetails .aEWZJW_content > table > thead {
  z-index: 1;
  position: sticky;
  top: 0;
}

.aEWZJW_languageDetails .aEWZJW_content > table > thead tr > th {
  font: var(--font-label-large);
  color: var(--color-text);
  background-color: var(--color-layer-1);
  padding: 4px 20px;
}

.aEWZJW_languageDetails .aEWZJW_content > table > tbody > tr > td {
  border: none;
  padding: 8px 20px;
}

.aEWZJW_languageDetails .aEWZJW_content .aEWZJW_customValuesColumn {
  align-items: center;
  display: flex;
}

.aEWZJW_languageDetails .aEWZJW_content .aEWZJW_clearButton {
  margin-left: 4px;
}

.aEWZJW_languageDetails .aEWZJW_content .aEWZJW_clearIcon {
  width: 16px;
  height: 16px;
}

.aEWZJW_languageDetails .aEWZJW_content .aEWZJW_sectionTitle {
  color: var(--color-text-secondary);
  font: var(--font-subhead-cap);
  letter-spacing: .1em;
  text-transform: uppercase;
  background-color: var(--color-layer-light);
}

.aEWZJW_languageDetails .aEWZJW_content .aEWZJW_sectionDataKey {
  font: var(--font-body-medium);
  color: var(--color-text);
  padding: 16px 20px;
}

.aEWZJW_languageDetails .aEWZJW_content .aEWZJW_sectionBuiltInText {
  padding: 8px 0;
}

.aEWZJW_languageDetails .aEWZJW_footer {
  border-top: 1px solid var(--color-divider);
  flex-direction: row-reverse;
  padding: 20px;
  display: flex;
}

@keyframes I1-gGa_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes I1-gGa_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.I1-gGa_languageSelector .I1-gGa_input {
  position: relative;
}

.I1-gGa_languageSelector .I1-gGa_input .I1-gGa_addLanguageButton {
  width: 100%;
  height: 38px;
  background: unset;
}

.I1-gGa_languageSelector .I1-gGa_input .I1-gGa_buttonIcon {
  color: var(--color-text-secondary);
}

.I1-gGa_languageSelector .I1-gGa_dropDown {
  width: 168px;
  background: var(--color-float);
  border: 1px solid var(--color-divider);
  max-height: 288px;
  box-shadow: var(--shadow-2);
  border-radius: 8px;
  margin: 4px 0;
  padding: 4px;
  position: absolute;
  overflow-y: auto;
}

.I1-gGa_languageSelector .I1-gGa_dropDown .I1-gGa_dropDownItem {
  width: 100%;
  cursor: pointer;
  font: var(--font-body-medium);
  border-radius: 8px;
  padding: 8px;
  list-style: none;
}

.I1-gGa_languageSelector .I1-gGa_dropDown .I1-gGa_dropDownItem:hover {
  background: var(--color-hover);
}

.I1-gGa_languageSelector .I1-gGa_dropDown .I1-gGa_dropDownItem .I1-gGa_languageName {
  color: var(--color-text);
}

.I1-gGa_languageSelector .I1-gGa_dropDown .I1-gGa_dropDownItem .I1-gGa_languageTag {
  color: var(--color-text-secondary);
}

.I1-gGa_languageSelector .I1-gGa_hidden {
  display: none;
}

@keyframes _3FhG7W_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _3FhG7W_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._3FhG7W_languageItem {
  cursor: pointer;
  border-radius: 8px;
  margin-bottom: 4px;
  padding: 6px 12px;
}

._3FhG7W_languageItem ._3FhG7W_languageName {
  color: var(--color-text);
  font: var(--font-label-large);
}

._3FhG7W_languageItem ._3FhG7W_languageTag {
  color: var(--color-text-secondary);
  font: var(--font-body-medium);
}

._3FhG7W_languageItem:hover {
  background-color: var(--color-hover-variant);
}

._3FhG7W_languageItem._3FhG7W_selected {
  background-color: var(--color-focused-variant);
}

._3FhG7W_languageItem._3FhG7W_selected ._3FhG7W_languageName, ._3FhG7W_languageItem._3FhG7W_selected ._3FhG7W_languageTag {
  color: var(--color-text-link);
}

@keyframes fvC3Ua_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes fvC3Ua_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.fvC3Ua_languageNav {
  width: 185px;
  background-color: var(--color-layer-light);
  border-right: 1px solid var(--color-divider);
  flex-shrink: 0;
  padding: 12px 8px;
}

.fvC3Ua_languageNav .fvC3Ua_languageItemList {
  height: 569px;
  margin-top: 12px;
  overflow-y: auto;
}

.RhR3EG_container {
  border: 1px solid var(--color-divider);
  border-radius: 8px;
  flex-direction: row;
  display: flex;
  overflow: hidden;
}

@keyframes ZLT1Wq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes ZLT1Wq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.ZLT1Wq_container {
  background-color: var(--color-surface-1);
  height: 100vh;
  flex-direction: column;
  display: flex;
}

.ZLT1Wq_container .ZLT1Wq_header {
  background-color: var(--color-layer-1);
  height: 64px;
  flex-shrink: 0;
  align-items: center;
  padding: 0 84px 0 8px;
  display: flex;
}

.ZLT1Wq_container .ZLT1Wq_header button {
  margin-left: 16px;
}

.ZLT1Wq_container .ZLT1Wq_header .ZLT1Wq_separator {
  border-left: 1px solid var(--color-border);
  width: 0;
  height: 20px;
  margin: 0 20px 0 16px;
}

.ZLT1Wq_container .ZLT1Wq_header .ZLT1Wq_closeIcon {
  color: var(--color-text-secondary);
}

.ZLT1Wq_container .ZLT1Wq_content {
  flex-direction: column;
  flex: 1;
  align-items: center;
  overflow-y: auto;
}

.ZLT1Wq_container .ZLT1Wq_content .ZLT1Wq_reminder {
  width: 550px;
  margin: 0 auto 32px;
}

.ZLT1Wq_container .ZLT1Wq_content > .ZLT1Wq_form {
  padding: 24px 68px 108px;
  position: relative;
}

.ZLT1Wq_container .ZLT1Wq_main {
  max-width: 1168px;
  min-width: 604px;
  align-items: flex-start;
  margin: 0 auto;
  display: flex;
}

.ZLT1Wq_container .ZLT1Wq_main .ZLT1Wq_form {
  flex: 1;
}

.ZLT1Wq_container .ZLT1Wq_main .ZLT1Wq_form > :not(:first-child) {
  margin-top: 12px;
}

.ZLT1Wq_container .ZLT1Wq_main .ZLT1Wq_preview {
  flex: 1;
  margin-left: 32px;
  position: sticky;
  top: 16px;
}

.ZLT1Wq_container .ZLT1Wq_footer {
  background: var(--color-layer-1);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.ZLT1Wq_container .ZLT1Wq_footer .ZLT1Wq_footerContent {
  max-width: 1168px;
  min-width: 604px;
  justify-content: right;
  margin: 0 auto;
  padding: 24px 0;
  display: flex;
}

@keyframes vFdCpa_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes vFdCpa_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.vFdCpa_container {
  height: 100%;
  min-height: 528px;
  padding-bottom: 24px;
}

.vFdCpa_welcome {
  height: 100%;
  flex-direction: column;
  display: flex;
}

.vFdCpa_welcome .vFdCpa_cardTitle {
  flex-shrink: 0;
}

.vFdCpa_welcome .vFdCpa_content {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 16px;
  display: flex;
}

.vFdCpa_welcome .vFdCpa_content > :not(:last-child) {
  margin-bottom: 20px;
}

.vFdCpa_welcome .vFdCpa_content .vFdCpa_text {
  font: var(--font-body-medium);
  max-width: 410px;
  text-align: center;
}

@keyframes Wc-taa_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes Wc-taa_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.Wc-taa_container {
  height: 100%;
  flex-direction: column;
  display: flex;
}

.Wc-taa_container .Wc-taa_cardTitle {
  flex-shrink: 0;
}

.Wc-taa_container .Wc-taa_tabs {
  margin: 16px 0;
}

.Wc-taa_container .Wc-taa_content {
  flex-direction: column;
  flex: 1;
  display: flex;
}

.Wc-taa_container .Wc-taa_content .Wc-taa_contentTop {
  flex: 1;
  margin-bottom: 24px;
  display: flex;
}

.Wc-taa_container .Wc-taa_content .Wc-taa_contentTop.Wc-taa_withSubmitActionBar {
  margin-bottom: 12px;
}

.Wc-taa_container .Wc-taa_content .Wc-taa_contentTop .Wc-taa_form {
  flex: 1;
  margin-right: 12px;
}

.Wc-taa_container .Wc-taa_content .Wc-taa_contentTop .Wc-taa_preview {
  flex: 1;
  align-self: flex-start;
  position: sticky;
  top: 16px;
}

.vRMsLW_hide {
  display: none;
}

@keyframes jVPuRW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes jVPuRW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.jVPuRW_transfer .jVPuRW_main {
  border: 1px solid var(--color-border);
  border-radius: 6px;
  display: flex;
}

.jVPuRW_transfer .jVPuRW_main .jVPuRW_box {
  width: 50%;
}

.jVPuRW_transfer .jVPuRW_main .jVPuRW_box:not(:last-child) {
  border-right: 1px solid var(--color-border);
}

.jVPuRW_transfer .jVPuRW_main .jVPuRW_box .jVPuRW_title {
  font: var(--font-subhead-2);
  border-bottom: 1px solid var(--color-border);
  padding: 12px 16px;
}

.jVPuRW_transfer .jVPuRW_main .jVPuRW_box .jVPuRW_items {
  height: 200px;
  overflow-y: auto;
}

.jVPuRW_transfer .jVPuRW_main .jVPuRW_box .jVPuRW_items .jVPuRW_item {
  font: var(--font-body-medium);
  align-items: center;
  padding: 8px 16px;
  display: flex;
}

.jVPuRW_transfer .jVPuRW_main .jVPuRW_box .jVPuRW_items .jVPuRW_item.jVPuRW_draggable {
  cursor: move;
}

.jVPuRW_transfer .jVPuRW_main .jVPuRW_box .jVPuRW_items .jVPuRW_item.jVPuRW_draggable .jVPuRW_draggableIcon {
  color: var(--color-text-secondary);
}

.jVPuRW_transfer .jVPuRW_main .jVPuRW_box .jVPuRW_items .jVPuRW_item > svg {
  margin-left: -5px;
  margin-right: 8px;
}

.jVPuRW_transfer .jVPuRW_main .jVPuRW_box .jVPuRW_items .jVPuRW_item:hover {
  background: var(--color-hover);
}

.jVPuRW_transfer .jVPuRW_main .jVPuRW_box .jVPuRW_items .jVPuRW_item .jVPuRW_itemTitle {
  flex: 1;
}

.jVPuRW_transfer .jVPuRW_main .jVPuRW_box .jVPuRW_items .jVPuRW_dragging .jVPuRW_item:hover {
  background: initial;
}

.jVPuRW_transfer .jVPuRW_footer {
  font: var(--font-body-medium);
  color: var(--color-text-secondary);
  margin-top: 8px;
}

.jVPuRW_transfer .jVPuRW_footer a {
  color: var(--color-text-link);
  text-decoration: none;
}

@keyframes A4GuyW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes A4GuyW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.A4GuyW_draggleItemContainer {
  transform: translate(0);
}

.A4GuyW_signInMethodItem {
  align-items: center;
  margin: 8px 0;
  display: flex;
}

.A4GuyW_signInMethod {
  height: 44px;
  width: 100%;
  background-color: var(--color-layer-2);
  cursor: move;
  color: var(--color-text);
  border-radius: 8px;
  align-items: center;
  margin-right: 8px;
  padding: 12px 8px;
  display: flex;
}

.A4GuyW_signInMethod.A4GuyW_error {
  outline: 1px solid var(--color-error);
}

.A4GuyW_signInMethod .A4GuyW_identifier {
  width: 130px;
  font: var(--font-label-large);
  align-items: center;
  display: flex;
}

.A4GuyW_signInMethod .A4GuyW_authentication {
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px;
  display: flex;
}

.A4GuyW_signInMethod .A4GuyW_authentication .A4GuyW_checkBox {
  width: 100%;
  flex-grow: 1;
}

.A4GuyW_signInMethod .A4GuyW_authentication .A4GuyW_swapButton {
  margin-right: 16px;
}

.A4GuyW_signInMethod .A4GuyW_authentication.A4GuyW_verifyCodePrimary {
  flex-direction: row-reverse;
}

.A4GuyW_signInMethod .A4GuyW_draggableIcon {
  color: var(--color-text-secondary);
  margin-right: 4px;
}

.A4GuyW_plusIcon {
  color: var(--color-text-secondary);
}

.A4GuyW_addAnotherSignInMethodDropdown {
  min-width: 208px;
}

.A4GuyW_addSignInMethodDropDown {
  min-width: unset;
}

.A4GuyW_errorMessage {
  font: var(--font-body-medium);
  color: var(--color-error);
}

@keyframes DrHO0W_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes DrHO0W_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.DrHO0W_checkbox {
  align-items: center;
  display: flex;
  position: relative;
}

.DrHO0W_checkbox .DrHO0W_icon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.DrHO0W_checkbox .DrHO0W_icon > svg {
  color: var(--color-neutral-60);
  display: none;
}

.DrHO0W_checkbox .DrHO0W_icon > svg:first-child {
  color: var(--color-primary);
}

.DrHO0W_checkbox input, .DrHO0W_checkbox .DrHO0W_disabledMask {
  width: 20px;
  height: 20px;
  opacity: 0;
  cursor: default;
  margin: 0;
  position: absolute;
  top: 0;
  left: 2px;
}

.DrHO0W_checkbox input:checked:not(:disabled) ~ .DrHO0W_icon > svg:nth-child(1), .DrHO0W_checkbox input:not(:checked):not(:disabled) ~ .DrHO0W_icon > svg:nth-child(2), .DrHO0W_checkbox input:checked:disabled ~ .DrHO0W_icon > svg:nth-child(3), .DrHO0W_checkbox input:not(:checked):disabled ~ .DrHO0W_icon > svg:nth-child(4) {
  display: block;
}

.DrHO0W_checkbox label {
  font: var(--font-body-medium);
  color: var(--color-text);
}

@keyframes _8sspFa_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _8sspFa_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._8sspFa_dropdown {
  min-width: unset;
}

._8sspFa_addAnotherDropdown {
  min-width: 208px;
}

._8sspFa_plusIcon {
  color: var(--color-text-secondary);
}

._8sspFa_title {
  align-items: center;
  display: flex;
}

._8sspFa_title ._8sspFa_logo {
  width: 20px;
  height: 20px;
  margin-right: 12px;
}

._8sspFa_title ._8sspFa_name {
  font: var(--font-body-medium);
}

._8sspFa_title ._8sspFa_icon {
  width: 16px;
  height: 16px;
  object-fit: cover;
  color: var(--color-text-secondary);
  margin-left: 4px;
}

@keyframes cK8lyq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes cK8lyq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.cK8lyq_item {
  align-items: center;
  margin: 8px 0;
  display: flex;
}

.cK8lyq_item .cK8lyq_info {
  height: 44px;
  width: 100%;
  background-color: var(--color-layer-2);
  cursor: move;
  color: var(--color-text);
  border-radius: 8px;
  align-items: center;
  margin-right: 8px;
  padding: 12px 8px;
  display: flex;
}

.cK8lyq_item .cK8lyq_info .cK8lyq_draggableIcon {
  color: var(--color-text-secondary);
}

.cK8lyq_item .cK8lyq_info .cK8lyq_logo {
  width: 20px;
  height: 20px;
  margin: auto 12px;
}

.cK8lyq_item .cK8lyq_info .cK8lyq_name {
  font: var(--font-label-large);
}

.cK8lyq_item .cK8lyq_info .cK8lyq_icon {
  width: 16px;
  height: 16px;
  object-fit: cover;
  color: var(--color-text-secondary);
  margin-left: 4px;
}

@keyframes GhWS2q_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes GhWS2q_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.GhWS2q_draggleItemContainer {
  transform: translate(0);
}

.GhWS2q_setUpHint {
  font: var(--font-body-medium);
  color: var(--color-text-secondary);
  margin-top: 8px;
}

.GhWS2q_setUpHint a {
  color: var(--color-text-link);
  text-decoration: none;
}

@keyframes _4OVbza_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _4OVbza_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._4OVbza_content {
  font: var(--font-body-medium);
}

._4OVbza_content ._4OVbza_info {
  background: var(--color-neutral-variant-90);
  border-radius: 8px;
  margin-top: 24px;
  padding: 20px;
}

._4OVbza_content ._4OVbza_info ._4OVbza_infoLine {
  align-items: center;
  display: flex;
}

._4OVbza_content ._4OVbza_info ._4OVbza_infoLine:not(:last-child) {
  margin-bottom: 8px;
}

._4OVbza_content ._4OVbza_info ._4OVbza_infoLine ._4OVbza_infoContent {
  padding-left: 4px;
  font-weight: bold;
}

._4OVbza_content ._4OVbza_info ._4OVbza_infoLine ._4OVbza_operation {
  padding-left: 4px;
}

._4OVbza_content ._4OVbza_info ._4OVbza_infoLine ._4OVbza_eyeIcon {
  color: var(--color-text-secondary);
}

@keyframes wCemyq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes wCemyq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.wCemyq_logs {
  flex: 1;
  margin-bottom: 24px;
  overflow-y: auto;
}

@keyframes XLffiW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes XLffiW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.XLffiW_empty {
  color: var(--color-text-secondary);
  font: var(--font-body-medium);
}

.XLffiW_table thead th {
  padding: 8px;
}

.XLffiW_table thead th:first-child {
  padding-left: 16px;
}

.XLffiW_table tbody tr td {
  font: var(--font-body-medium);
  padding: 12px 8px;
}

.XLffiW_table tbody tr td:first-child, .XLffiW_table tbody tr td:last-child {
  padding-left: 16px;
}

.XLffiW_table tbody tr:last-child td {
  border-bottom: none;
}

.XLffiW_table .XLffiW_connectorName {
  align-items: center;
  display: flex;
}

.XLffiW_table .XLffiW_connectorName img {
  width: 32px;
  height: 32px;
  border-radius: 8px;
}

.XLffiW_table .XLffiW_connectorName .XLffiW_name {
  margin-left: 12px;
}

.XLffiW_table .XLffiW_connectorId {
  font: var(--font-body-medium);
  align-items: center;
  font-family: Roboto Mono, monospace;
  line-height: 32px;
  display: flex;
}

.XLffiW_table .XLffiW_connectorId span {
  max-width: 220px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@keyframes tRtS8W_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes tRtS8W_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.tRtS8W_backLink {
  margin: 4px 0 0 4px;
}

.tRtS8W_resourceLayout {
  height: 100%;
}

.tRtS8W_header {
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  display: flex;
}

.tRtS8W_header > :not(:first-child) {
  margin-left: 24px;
}

.tRtS8W_header .tRtS8W_avatar {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 8px;
  margin-left: 8px;
}

.tRtS8W_header .tRtS8W_metadata {
  flex: 1;
}

.tRtS8W_header .tRtS8W_metadata > div {
  align-items: center;
  display: flex;
}

.tRtS8W_header .tRtS8W_metadata > div > :not(:last-child) {
  margin-right: 8px;
}

.tRtS8W_header .tRtS8W_metadata .tRtS8W_name {
  font: var(--font-title-large);
  color: var(--color-text);
}

.tRtS8W_header .tRtS8W_metadata .tRtS8W_suspended {
  background: var(--color-error-container);
  color: var(--color-text);
  font: var(--font-label-medium);
  border-radius: 10px;
  padding: 2px 6px;
}

.tRtS8W_header .tRtS8W_metadata .tRtS8W_username {
  color: var(--color-text-secondary);
  font: var(--font-subhead-2);
}

.tRtS8W_header .tRtS8W_metadata .tRtS8W_text {
  font: var(--font-subhead-2);
  color: var(--color-text-secondary);
}

.tRtS8W_header .tRtS8W_metadata .tRtS8W_verticalBar {
  border-left: 1px solid var(--color-border);
  width: 0;
  height: 12px;
}

.tRtS8W_header .tRtS8W_moreIcon, .tRtS8W_resetIcon {
  color: var(--color-text-secondary);
}

@keyframes _9nt4fW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _9nt4fW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._9nt4fW_search {
  align-items: center;
  display: flex;
}

._9nt4fW_search :not(:first-child) {
  margin-left: 8px;
}

._9nt4fW_search ._9nt4fW_searchInput {
  width: 286px;
}

._9nt4fW_search ._9nt4fW_searchIcon {
  color: var(--color-text-secondary);
}

@keyframes yPRQrG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes yPRQrG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.yPRQrG_filter {
  background-color: var(--color-layer-1);
  border-bottom: 1px solid var(--color-divider);
  border-radius: 12px 12px 0 0;
  padding: 12px;
}

.yPRQrG_tableLayout {
  flex-direction: column;
  display: flex;
}

.yPRQrG_tableLayout .yPRQrG_tableContainer {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.yPRQrG_pagination {
  margin-top: 16px;
}

.yPRQrG_avatar {
  width: 28px;
  height: 28px;
  object-fit: cover;
  border-radius: 6px;
  flex-shrink: 0;
}

.yPRQrG_userName {
  width: 360px;
  font: var(--font-body-medium);
}

@keyframes hwnTFa_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes hwnTFa_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.hwnTFa_container {
  background-color: var(--color-surface-1);
  height: 100vh;
  min-width: 1280px;
  min-height: 806px;
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: contain;
  flex-direction: column;
  display: flex;
}

.hwnTFa_container.hwnTFa_light {
  background-image: url("welcome.0827ebe6.svg");
}

.hwnTFa_container.hwnTFa_dark {
  background-image: url("welcome-dark.837d4c95.svg");
}

.hwnTFa_header {
  width: 100%;
  padding: 48px;
  position: absolute;
  top: 0;
  left: 0;
}

.hwnTFa_logo {
  height: 32px;
  width: auto;
  color: var(--color-text);
}

main {
  flex-direction: column;
  flex: 1;
  display: flex;
}

.hwnTFa_placeholderTop {
  flex: 1;
}

.hwnTFa_placeholderBottom {
  flex: 2;
}

.hwnTFa_content {
  width: 585px;
  margin-left: 98px;
}

.hwnTFa_title {
  letter-spacing: -.25px;
  color: var(--color-text);
  margin: 8px 0 12px;
  font-size: 57px;
  font-weight: 600;
  line-height: 64px;
}

.hwnTFa_description {
  font: var(--font-body-large);
  color: var(--color-text-secondary);
  margin-bottom: 32px;
}

.hwnTFa_button {
  width: 400px;
}

/*# sourceMappingURL=index.6fbe68fb.css.map */
